import React from 'react';
import './style.css';

 const SalesCard=()=>{
    return <div className='col-xxl-4 col-md-6'>
        <div class="graph-card ">
        <div class="card-body">
        <h5 class="card-title">{'Recent Orders'} <span>| {'Week'}</span></h5>
        </div>
    </div>
    </div>
}
export default SalesCard