import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import profileIcon from '../../assets/user.png'

import { getActiveOrderList} from '../../redux/actions/orderAction';
import './rider.css';

import { Loader } from '../../component/loader';

const RiderList = (props) => {
    const dispatch = useDispatch();
    const [activrOderArray,setActiveOrderArray]=useState([])
    const [loading, setLoading] = useState(false);

    const activeOrderList = useSelector((state) => state.getActiveOrderReducer);


    useEffect(() => {
      setLoading(true)
    //dispatch(getActiveOrderList());
  }, [])

  useEffect(() => {
    if(activeOrderList){
      console.log('activeOrderList',activeOrderList)
      setLoading(false)
      setActiveOrderArray(activeOrderList)
    }

}, [activeOrderList])

const toggleAddRiderPopup=()=>{
   
  }


    return <>
        <div class="product-card ">
        <div class="card-body">
        <div className='title-container'>
        <h5 class="card-title ">{'All Riders'}  </h5>

        <h5 class="card-title ">{`Total (${activrOderArray.length})`}  </h5>
        <Button onClick={toggleAddRiderPopup}
                    className='primary-button'
                    style={{ margin: "10px", padding: "10px", fontWeight: "700" }}>
                    <i class="bi bi-plus-square mr-5"></i> Add New Rider
                  </Button>

        </div>
        {loading &&<Loader loading={loading}/>}

                <div className='row-home mt-20'>
                    <img src={profileIcon} alt="profile" width="100" height="100" className='offline' />
                    <div className='column-home ml-20'>
                        <div className='name'>Manoj Singh</div>
                        <div><span>Contact Number:</span> 8888333332</div>
                        <div><span>Address:</span> Ward No 16, Ashok nagar, Barabanki, UP - 225001</div>
                        <div><span>Status:</span> Offline</div>


                    </div>
              </div>

              <div className='row-home mt-20'>
                    <img src={profileIcon} alt="profile" width="100" height="100"  className='online'/>
                    <div className='column-home ml-20'>
                        <div className='name'>Ajay Kumar</div>
                        <div><span>Contact Number:</span> 89588354332</div>
                        <div><span>Address: Road no:</span> 5, New Bazar, Barabanki, UP - 225001</div>
                        <div><span>Status:</span> online</div>


                    </div>
              </div>

              <div className='row-home mt-20'>
                    <img src={profileIcon} alt="profile" width="100" height="100"  className='online'/>
                    <div className='column-home ml-20'>
                        <div className='name'>Bipin Kumar</div>
                        <div><span>Contact Number:</span> 7733354332</div>
                        <div><span>Address: Ward No:</span> 10, Ap Colony, Barabanki, UP - 225001</div>
                        <div><span>Status:</span> Online</div>

                    </div>
              </div>

                  </div>
                  </div>
    </>
}

export default RiderList;