import React from 'react';
import './style.css';
import profileIcon from '../../../assets/user.png'


 const RightRiderCard=({title,subtitle,list})=>{
    return <div className='col-xxl-4 col-md-6'>
        <div class="order-card ">
        <div class="card-body">
                  <h6 class="card-title">{title} <span>| {subtitle}</span></h6>
                  <div className='row-home mt-20'>
                    <img src={profileIcon} alt="profile" width="50" height="50" className='offline' />
                    <div className='column-home ml-20'>
                        <div className='name-dashboard'>Manoj Singh</div>
                        <div><span>Contact Number:</span> 8888333332</div>
                        <div><span>Address:</span> Ward No 16, Ashok nagar, Brabanki, UP - 225001</div>
                        <div><span>Status:</span> Offline</div>


                    </div>
              </div>

              <div className='row-home mt-20'>
                    <img src={profileIcon} alt="profile" width="50" height="50"   className='online'/>
                    <div className='column-home ml-20'>
                        <div className='name-dashboard'>Ajay Verma</div>
                        <div><span>Contact Number:</span> 895883542</div>
                        <div><span>Address: Road no:</span> 5, New Bazar, Brabanki, UP - 225001</div>
                        <div><span>Status:</span> online</div>


                    </div>
              </div>

              <div className='row-home mt-20'>
                    <img src={profileIcon} alt="profile" width="50" height="50"   className='online'/>
                    <div className='column-home ml-20'>
                        <div className='name-dashboard'>Bipin Kumar</div>
                        <div><span>Contact Number:</span> 7733354332</div>
                        <div><span>Address: Ward No:</span> 10, Ap Colony, Brabanki, UP - 225001</div>
                        <div><span>Status:</span> Online</div>

                    </div>
              </div>
                  {/* <div class="show-more">Show More</div> */}
                  </div>
      
    </div>
    </div>
}
export default RightRiderCard