export const SET_FIRST_NAME = "SET_FIRST_NAME";
export const SET_LAST_NAME = "SET_LAST_NAME";

export const VERIFY_USER = "VERIFY_USER";

export const ADD_CATALOGUE = "ADD_CATALOGUE";
export const UPDATE_CATALOGUE = "UPDATE_CATALOGUE";
export const DELETE_CATALOGUE = "DELETE_CATALOGUE";
export const GET_CATALOGUES = "GET_CATALOGUES";
export const CLEAR_UPLOAD_CATALOGUE="CLEAR_UPLOAD_CATALOGUE";

export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const GET_CATEGORY = "GET_CATEGORY";
export const CLEAR_UPLOAD_CATEGORY="CLEAR_UPLOAD_CATEGORY";

export const UPLOAD_FILE = "UPLOAD_FILE";

export const GET_PRODUCTS= "GET_PRODUCTS";
export const GET_PRODUCTS_BY_CATEGORY= "GET_PRODUCTS_BY_CATEGORY";
export const ADD_PRODUCT="ADD_PRODUCT";
export const DELETE_PRODUCT="DELETE_PRODUCT";
export const UPDATE_PRODUCT="UPDATE_PRODUCT";
export const CLEAR_UPDATE_PRODUCTS="CLEAR_UPDATE_PRODUCTS"
export const CLEAR_DELETE_PRODUCT="CLEAR_DELETE_PRODUCT";


export const GET_ACTIVE_ORDERS= "GET_ACTIVE_ORDERS";

export const GET_PENDING_ORDERS= "GET_PENDING_ORDERS";

export const UPDATE_ORDERS= "UPDATE_ORDERS";

export const GET_CUSTOMER_COUNT= "GET_CUSTOMER_COUNT";

export const GET_ADMIN_USERS= "GET_ADMIN_USERS";
export const DELETE_ADMIN_USER= "DELETE_ADMIN_USER";

export const GET_COURIERS_LIST= "GET_COURIERS_LIST";
export const FETCH_COURIER_TOKEN= "FETCH_COURIER_TOKEN";

export const GET_OFFER_LIST= "GET_OFFER_LIST";
export const GET_MEMBERS= "GET_MEMBERS";






export const LOG_OUT = "LOG_OUT";
export const CLEAR_STATE = "CLEAR_STATE";



