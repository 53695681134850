import {UPLOAD_FILE } from "../types";

const INITIAL_STATE = null;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPLOAD_FILE:
      return {
        ...action.payload,
      };
    

    default:
      return INITIAL_STATE;
  }
};
