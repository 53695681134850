import { GET_PRODUCTS,ADD_PRODUCT,GET_PRODUCTS_BY_CATEGORY,DELETE_PRODUCT,UPDATE_PRODUCT,CLEAR_UPDATE_PRODUCTS} from "../types";
import {getAPI,postAPI,deleteAPI,putAPI} from "../../services/apiService"
import {GET_PRODUCT_URL,ADD_PRODUCT_URL,GET_PRODUCT_BY_CATEGORY_URL,DELETE_PRODUCT_URL,UPDATE_PRODUCT_URL} from "../../services/apiConstant"



export const addProduct = (data) => async (dispatch, getState) => {
  try {
    const addProductResponse= await postAPI(ADD_PRODUCT_URL,data);
    dispatch({
      type: ADD_PRODUCT,
      payload: addProductResponse,
    });
  
  } catch (error) {
    console.log("Error", error);
  }
};

export const getProductList = () => async (dispatch, getState) => {
  try {
    const productsResponse= await getAPI(GET_PRODUCT_URL);
    console.log('productsResponse-',productsResponse);
    dispatch({
      type: GET_PRODUCTS,
      payload: productsResponse,
    });
  } catch (error) {
    console.log("Error", error);
  }
};

export const getProductsByCategory= (categoryId) => async (dispatch, getState) => {
  try {
    const productsResponse= await getAPI(GET_PRODUCT_BY_CATEGORY_URL+"/"+categoryId);
    console.log('productsResponse-',productsResponse);
    dispatch({
      type: GET_PRODUCTS_BY_CATEGORY,
      payload: productsResponse,
    });
  } catch (error) {
    console.log("Error", error);
  }
};

export const updateProduct = (body) => async (dispatch, getState) => {
  try {
    const updateProductResponse= await putAPI(UPDATE_PRODUCT_URL,body);
    dispatch({
      type: UPDATE_PRODUCT,
      payload: updateProductResponse,
    });
  
  } catch (error) {
    console.log("Error", error);
  }
};

export const deleteProduct = (id,body) => async (dispatch, getState) => {
  try {
    const deleteProductResponse= await deleteAPI(DELETE_PRODUCT_URL+id,body);
    dispatch({
      type: DELETE_PRODUCT,
      payload: deleteProductResponse,
    });
  
  } catch (error) {
    console.log("Error", error);
  }
};

export const clearUpdateProduct = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLEAR_UPDATE_PRODUCTS,
      payload: undefined,
    });
  } catch (error) {
    console.log("Error", error);
  }
};




