import { GET_CATALOGUES,ADD_CATALOGUE ,DELETE_CATALOGUE,UPDATE_CATALOGUE,CLEAR_UPLOAD_CATALOGUE} from "../types";
import {postAPI,getAPI, deleteAPI,putAPI} from "../../services/apiService"
import {ADD_CATALOGUE_URL,GET_CATALOGUE_URL,DELETE_CATALOGUE_URL,UPDATE_CATALOGUE_URL} from "../../services/apiConstant"
import firebase from 'firebase/compat/app';



export const addCatalogue = (data) => async (dispatch, getState) => {
  try {
    const addCatalogueResponse= await postAPI(ADD_CATALOGUE_URL,data);
    dispatch({
      type: ADD_CATALOGUE,
      payload: addCatalogueResponse,
    });
  
  } catch (error) {
    console.log("Error", error);
  }
};
export const  updateCatalogue = (data) => async (dispatch, getState) => {
  try {
    const updateCatalogueResponse= await putAPI(UPDATE_CATALOGUE_URL,data);
    console.log('updateCatalogueResponse-',updateCatalogueResponse);

    dispatch({
      type: UPDATE_CATALOGUE,
      payload: updateCatalogueResponse,
    });
  
  } catch (error) {
    console.log("Error", error);
  }
};

export const getCatalogueList = () => async (dispatch, getState) => {
  try {
    const user= await firebase.auth().currentUser.getIdTokenResult()
    const catalogueResponse= await getAPI(GET_CATALOGUE_URL,user?.token);
    console.log('catalogueResponse-',catalogueResponse);
    dispatch({
      type: GET_CATALOGUES,
      payload: catalogueResponse,
    });
  } catch (error) {
    console.log("getCatalogueList Error", error);
  }
};

export const deleteCatalogue = (id,body) => async (dispatch, getState) => {
  try {
    const deleteCatalogueResponse= await deleteAPI(DELETE_CATALOGUE_URL+id,body);
    dispatch({
      type: DELETE_CATALOGUE,
      payload: deleteCatalogueResponse,
    });
  
  } catch (error) {
    console.log("Error", error);
  }
};


export const clear = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLEAR_UPLOAD_CATALOGUE,
      payload: undefined,
    });
  } catch (error) {
    console.log("Error", error);
  }
};


