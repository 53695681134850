import {UPDATE_CATALOGUE, CLEAR_STATE,CLEAR_UPLOAD_CATALOGUE } from "../types";

const INITIAL_STATE = null;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CATALOGUE:
      return {
        ...action.payload,
      };
      
        case  CLEAR_UPLOAD_CATALOGUE:
        return INITIAL_STATE;

    default:
      return INITIAL_STATE;
  }
};
