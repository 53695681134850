import thunk from "redux-thunk";
import { createStore, applyMiddleware, combineReducers } from "redux";
import userReducer from "../reducers/userReducer";
import uploadFileReducer from "../reducers/uploadFileReducer";
import addCatalogueReducer from "../reducers/addCatalogueReducer";
import getCatalogueReducer from "../reducers/getCatalogueReducer";
import deleteCatalogueReducer from "../reducers/deleteCatalogueReducer"
import updateCatalogueReducer from "../reducers/updateCatalogueReducer";
import getProductReducer from "../reducers/getProductReducer"
import addProductReducer from "../reducers/addProductReducer"
import getProductByCategoryReducer from "../reducers/getProductByCategoryReducer"
import deleteProductReducer from "../reducers/deleteProductReducer";
import updateProductReducer from "../reducers/updateProductReducer"
import getActiveOrderReducer from "../reducers/getActiveOrderReducer";
import customerReducer from "../reducers/customerReducer";
import getCategoryReducer from "../reducers/getCategoryReducer"
import addCategoryReducer from "../reducers/addCategoryReducer";
import deleteCategoryReducer from "../reducers/deleteCategoryReducer";
import adminIUsersReducer from "../reducers/adminIUsersReducer";
import courierReducer from "../reducers/courierReducer";
import offerListReducer from "../reducers/offerListReducer"
import memberReducer from "../reducers/memberReducer"
import getPendingOrderReducer from "../reducers/getPendingOrderReducer";


const initialState = {};
const reducers = combineReducers({
  userReducer: userReducer,
  addCatalogueReducer:addCatalogueReducer,
  uploadFileReducer:uploadFileReducer,
  getCatalogueReducer:getCatalogueReducer,
  deleteCatalogueReducer:deleteCatalogueReducer,
  updateCatalogueReducer:updateCatalogueReducer,
  getProductReducer:getProductReducer,
  addProductReducer:addProductReducer,
  getProductByCategoryReducer:getProductByCategoryReducer,
  deleteProductReducer:deleteProductReducer,
  updateProductReducer:updateProductReducer,
  getActiveOrderReducer:getActiveOrderReducer,
  customerReducer:customerReducer,
  addCategoryReducer:addCategoryReducer,
  getCategoryReducer:getCategoryReducer,
  deleteCategoryReducer:deleteCategoryReducer,
  adminUsersReducer:adminIUsersReducer,
  courierReducer:courierReducer,
  offerListReducer:offerListReducer,
  memberReducer:memberReducer,
  getPendingOrderReducer:getPendingOrderReducer


});
const middleware = [thunk];

const store = createStore(
  reducers,
  initialState,
  applyMiddleware(...middleware)
);
export default store;
