import { GET_MEMBERS} from "../types";
import {getAPI} from "../../services/apiService"
import {GET_MEMBERS_URL} from "../../services/apiConstant"


export const getMemberList = () => async (dispatch, getState) => {
  try {
    const memberListResponse= await getAPI(GET_MEMBERS_URL);
    console.log('memberListResponse-',memberListResponse);
    dispatch({
      type: GET_MEMBERS,
      payload: memberListResponse,
    });
  } catch (error) {
    console.log("Error", error);
  }
};







