import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import './product.css';
import CatalogueDetailsCard from './component/catalogueDetailsCard'
import { getCatalogueList } from '../../redux/actions/catalogueAction';


  const CatalogueProduct=(props)=>{
   const [allCategory,setAllCategory]=useState([])
    const navigate = useNavigate();
    const dispatch = useDispatch();
    console.log('Product props',props)

    const categoryList = useSelector((state) => state.getCatalogueReducer);

    useEffect(() => {
    dispatch(getCatalogueList());
  }, [])

  useEffect(() => {
    if(categoryList){
      console.log('categoryList',categoryList)
     setAllCategory(categoryList)
    }

}, [categoryList])


    return <>
        <div className='row-home'>
          <div className='col-lg-8'>
            <div className='row'>
             
              <div className='column-home'>
              <CatalogueDetailsCard list={allCategory} handlePageSelect={props.handlePageSelect} selectedData={props.data} user={props.user}/>
              </div>
            </div>
          </div>

        </div>
    </>
  }

  export default CatalogueProduct;