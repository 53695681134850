import { UPLOAD_FILE } from "../types";
import {uploadFile} from "../../services/apiService"
import { UPLOAD_IMAGE_URL} from "../../services/apiConstant"


export const uploadImage = (data) => async (dispatch, getState) => {
  try {
    const uploadFileResponse= await uploadFile(UPLOAD_IMAGE_URL,data);
    //console.log('uploadImage uploadFileResponse',uploadFileResponse)
    dispatch({
      type: UPLOAD_FILE,
      payload: uploadFileResponse,
    });
  
  } catch (error) {
    console.log("Error", error);
  }
};

export const clear = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPLOAD_FILE,
        payload: undefined,
      });
    } catch (error) {
      console.log("Error", error);
    }
  };