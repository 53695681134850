import React from 'react';
import './style.css';

 const RightOrderCard=({title,subtitle,list,handlePageSelect})=>{
  const topList=list && list?.slice(0, 3) || []; //top 3 orders display right side
  console.log('topList',topList)
    return <div className='col-xxl-4 col-md-6'>
        <div class="order-card ">
        <div class="card-body">
                  <h6 class="card-title">{title} <span>| {subtitle}</span></h6>
                  {topList.map((data)=>{
                    return <div className='right-order-item'>
                        <div className='bold-font order-title'>#{data.orderId?.toUpperCase()}</div>
                        <div><span>Courier Partner:</span> {"Not Assign"}</div>
                        <div><span>Delivery Location:</span> {data?.deleiveryDetails?.selectedAddress?.city}</div>
                        <div><span>Delivery Time:</span> {data.deleiveryDetails.deliveryTimeSlot}</div>
                        <div><span>Status:</span> {data.orderStatus}</div>
                        <div className='tarck-text'>View Details</div>
                    </div>
                  })}
                  {topList.length>0 &&<div class="show-more" onClick={()=>handlePageSelect('orders')}>Show More</div>}

                  </div>
      
    </div>
    </div>
}
export default RightOrderCard