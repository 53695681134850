import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from "react-redux";
//import { addProduct, updateProduct } from '../../../redux/actions/productAction';
import { Loader } from '../../component/loader';
import ToastMessage from '../../component/toast'

import {ADD_ADMIN_USER_URL} from "../../services/apiConstant"
import {postAPI} from "../../services/apiService"

function AddAdminUserPopup({ show,togglePopup}) {

  const [userFullName, setUserFullName] = useState();
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRePassword] = useState('');
  const [role, setrole] = useState('');

  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const dispatch = useDispatch();

 const roles=['contentAdmin','admin','superAdmin','readOnly','vendor']

  const saveData = async() => {
    setLoading(true)
    let data = {
        "email":email,
        "password":password,
        "name":userFullName,
        "role":role,
        "mobileNumber":phoneNumber
    }
    console.log('data---', data);
    const addNewUserresponse= await postAPI(ADD_ADMIN_USER_URL,data);
    if(addNewUserresponse){
      setLoading(false)
      togglePopup(false)
    }
  }

  const validate = () => {
    if (userFullName && email && repassword
      && password && role) {
      return false
    }
    return true;
  }

  return (
    <>
      <Modal show={show} onHide={togglePopup} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title> {"Add New User"}
      </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className='flex-row'>
            <Form style={{ marginLeft: '20%' }}>
              <Form.Group className="mb-3" controlId="product.ControlInput1">
                <Form.Label>User Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter user fullName"
                  onChange={(e)=>setUserFullName(e.target.value)}
                  autoFocus
                  autoComplete="nope"
                  />
              </Form.Group>

              <Form.Label>Select User Role</Form.Label>
                <Form.Select aria-label="Default select example" size="lg"  onChange={(e)=>setrole(e.target.value)}>
                  <option>Please select user role</option>
                  {roles?.map((role)=>{
                    return  <option value={role}>{role}</option>
                  })}
               
                </Form.Select>

              <Form.Group className="mb-3" controlId="product.ControlInput1">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Email"
                  onChange={(e)=>setEmail(e.target.value)}
                  autoComplete="nope"
                  autoFocus
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="product.ControlInput1">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter Password"
                  onChange={(e)=>setPassword(e.target.value)}
                  autoComplete="nope"
                  autoFocus
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="product.ControlInput1">
                <Form.Label>Re Enter Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Re Enter Password"
                  onChange={(e)=>setRePassword(e.target.value)}
                  autoComplete="nope"
                  autoFocus
                />
              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="product.ControlInput1">
                <Form.Label>Phone Number (optional)</Form.Label>
                <Form.Control
                  type="text"
                  maxLength={10}
                  placeholder="Enter Mobile"
                  onChange={(e)=>setPhoneNumber(e.target.value)}
                  autoFocus
                />
              </Form.Group> */}

            </Form>

          </div>
          {loading ? <Loader loading={loading} /> : null}
        </Modal.Body>
        <Modal.Footer>
          <div className='flex-row justify'>
            <div className='flex-row'>
              <Button variant="secondary" onClick={togglePopup} style={{ marginRight: 10 }}>
                Close
              </Button>
              <Button className='primary-button' onClick={saveData} disabled={ validate()}>
                {"Register"}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddAdminUserPopup;