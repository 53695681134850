import Toast from 'react-bootstrap/Toast';

function ToastMessage({url,title}) {
  return (
    <Toast position="top-center" delay={2000} autohide >
      <Toast.Header>
        <img src={url} className="rounded me-2"  style={{width:'20px',height:'20px'}} alt="" />
        <strong className="me-auto">{title}</strong>
        <small>Just Now</small>
      </Toast.Header>
      <Toast.Body>{title} added successfully.</Toast.Body>
    </Toast>
  );
}

export default ToastMessage;