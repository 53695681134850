import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {ToggleSmall} from '../component/toggle'
import { useState } from 'react';

function CardIMage({data,handleToggleChange,handleUpdateCatalogueShow,showProductList}) {
 const[enable,setEnable]=useState(data?.enable);
 
 const onToggleChange=()=>{
        setEnable(!enable)
        data.enable=!enable
        handleToggleChange(data)
    }

    const handleOpenPopup=()=>{
      handleUpdateCatalogueShow(data);
    }
  return (
    <Card style={{ width: '265px',margin:'15px',padding:'10px' }}>
        <i class={enable?"bi bi-toggle-on toggle-left":"bi bi-toggle-off toggle-left"} onClick={onToggleChange}></i>
        <i class="bi bi-gear settings" onClick={handleOpenPopup}></i>
        {!enable &&<Card.ImgOverlay style={{}}/>}
        {/* <ToggleSmall handleToggleChange={onToggleChange}/> */}
      <Card.Img variant="top" src={data.imageUrl} className='image-card' />
      <div className='catType flex-row m-10'>
        <div className='bold-font'>Category:</div>
        <div>{data.categoryType}</div>
        </div>

      {data?.bundleOldPrice && <div className='catType flex-row m-10'>
        <div className='bold-font'>Old Price: </div>
        <div className='strike'>{data?.bundleOldPrice || ' '}</div>
      </div>
      }

      {data?.bundleNewPrice && <div className='catType flex-row '>
        <div className='bold-font'>New Price: </div>
        <div>{data?.bundleNewPrice || ' '}</div>
      </div>
      }

      <Card.Body style={{textAlign: "center"}}>
        <Card.Title style={{fontSize:'18px',textAlign:'center',fontWeight:'600'}}>{data.itemName}</Card.Title>
        <Card.Text className='card-desc'>
          {data.desc?data.desc:data.itemName}
        </Card.Text>
      
        <Button className='btn-view' variant="outline-secondary pointer-cursor"  onClick={()=>showProductList(data)}>{data?.productType==='bundle'?'View/Add Bundle':'View/Add Products'}</Button>
      </Card.Body>
    </Card>
  );
}

export default CardIMage;