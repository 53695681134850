import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ImageUpload from '../../../component/imageUpload';
import { useDispatch, useSelector } from "react-redux";
import { uploadImage, clear } from "../../../redux/actions/uploadImageAction";
import { updateCatalogue, deleteCatalogue } from '../../../redux/actions/catalogueAction';
import { Loader } from '../../../component/loader';
import ToastMessage from '../../../component/toast'
import { getCategoryList } from '../../../redux/actions/categoryAction';

const FOLDER_PATH = 'ProductCategory/catalogue'

function UpdateCategoryPopup({ data, showCatalogue, handleCloseCatalogue,user }) {
  console.log('data update', data)
  const dispatch = useDispatch();

  const [image, setImage] = useState({ preview: "", raw: "" });
  const [catalogueName, setCatalogueName] = useState(data?.itemName);
  const [catalogueDesName, setCatalogueDesName] = useState(data?.desc);
  const [bundlePrice, setBundlePrice] = useState(data?.bundleNewPrice);
  const [categoryType, setCategoryType] = useState(data?.categoryType);
  const [categoryID, setCategoryID] = useState(data?.mainCategoryId);

  const [bundleOldPrice, setBundleOldPrice] = useState(data?.bundleOldPrice);
  const [imageName, setImageName] = useState(data?.imageName);

  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [loyalityPoint, setLoyalityPoint] = useState(data?.loyalityPoint || 0);
  const [productType, setProductType] = useState(data?.productType);
  const [allCategory,setAllCategory]=useState([])

  const deleteFileReponse = useSelector((state) => state.deleteCatalogueReducer);
  const updateCatalogueSuccess = useSelector((state) => state.updateCatalogueReducer);

  const uploadFileReponse = useSelector((state) => state.uploadFileReducer);


  const categoryList = useSelector((state) => state.getCategoryReducer) || [];
  
  useEffect(() => {
    dispatch(getCategoryList());
  }, [])
  
  useEffect(() => {
    if(categoryList?.length>0){
      console.log('categoryList-',categoryList)
      setAllCategory(categoryList)
    }
  
  }, [categoryList])

  useEffect(() => {
    //make sure redux is clear on firt time page load
    dispatch(clear());
    setCategoryType(data?.categoryType)
    setCategoryID(data?.mainCategoryId)
  }, [])

  useEffect(() => {
    if (data) {
      initField();
    }

  }, [data])

  useEffect(() => {
    console.log('updateCatalogueSuccess', updateCatalogueSuccess)
    if (updateCatalogueSuccess) {
      dispatch(clear());
      setLoading(false)
      setShowToast(true)
      clearAllField()
      hideAllView();
    }
  }, [updateCatalogueSuccess])

  useEffect(() => {
    setLoading(false)
    if (deleteFileReponse && Object.keys(deleteFileReponse).length > 0) {
      handleCloseCatalogue(data)
    }
  }, [deleteFileReponse])



  const initField = () => {
    setCatalogueName(data?.itemName)
    setCatalogueDesName(data?.desc);
    setBundlePrice(data?.bundleNewPrice);
    setCategoryType(data?.categoryType)
    setBundleOldPrice(data?.bundleOldPrice);
    setImageName(data?.imageName)
    setLoyalityPoint(data?.loyalityPoint)
    setProductType(data?.productType)
    setCategoryID(data?.mainCategoryId)

  }

  const handleDeleteCatalogue = () => {
    setLoading(true)
    const body = { "imagePath": `${FOLDER_PATH}/${data.imageName}` }
    dispatch(deleteCatalogue(data.id, body))
  }

  const handleClosePopUp = (data) => {
    clearAllField()
    handleCloseCatalogue(data)
  }

  const saveData = (imageUrl) => {
    const saveData = {
      "id": data?.id,
      "imageUrl": imageUrl || data?.imageUrl,
      "imageName": imageName || data?.imageName,
      "itemCode": catalogueName,
      "desc": catalogueDesName,
      "itemType": catalogueName,
      "itemName": catalogueName,
      "mainCategoryId":categoryID,
      "categoryType": getCategoryTypeValue(categoryID),
      "enable": true,
      "bundleOldPrice": bundleOldPrice,
      "bundleNewPrice": bundlePrice,
      "productType": productType,
      "loyalityPointValue": Math.round(Number(bundlePrice) * (Number(loyalityPoint) / 100)),
      "loyalityPoint": loyalityPoint
    }
    console.log('saveData', saveData)

    dispatch(updateCatalogue(saveData));

    // handleCloseCatalogue();
    //setTimeout(hideAllView, 2000);
  }



  useEffect(() => {
    console.log('useEffect uploadFileReponse', uploadFileReponse)
    if (uploadFileReponse && Object.keys(uploadFileReponse).length > 0) {
      const imageUrl = uploadFileReponse.imagePublicUrl;
      if (imageUrl && catalogueName) {
        saveData(imageUrl);
      }
    }
  }, [uploadFileReponse])

  const clearAllField = () => {
    setCatalogueName('')
    setCatalogueDesName('');
    setBundlePrice('');
    setCategoryType('')
    setBundleOldPrice('');
    setLoyalityPoint(0);
    setProductType('');
    setImageName('')
  }

  const getCategoryTypeValue=(categoryId)=>{
    const filterCategory=allCategory.find((ac)=>ac.id===categoryId)
    return filterCategory?.categoryName
  
    }

  const hideAllView = () => {
    //clearAllField()
    handleCloseCatalogue(data);
    setShowToast(false)
  }


  const onFileSelect = (selectedImage) => {
    setImage(selectedImage);
    const imageName = `${new Date().getTime()}_${selectedImage.raw.name}`;
    setImageName(imageName)
  }

  const handleUpdateCategory = () => {
    setLoading(true)
    console.log('image.raw', image?.raw)

    if (image?.raw) {
      const formData = new FormData();
      formData.append("file", image.raw);
      formData.append("fileName", imageName);
      formData.append('bucketName', 'dhansheera')
      formData.append('folderName', FOLDER_PATH)
      dispatch(uploadImage(formData))
    }
    else {
      saveData()
    }

  }

  const handleCategoryChange = (e) => {
    console.log(e.target.value)
    setCategoryType(e.target.value)
    setCategoryID(e.target.value)
  }

  const handleCategoryNameChange = (e) => {
    setCatalogueName(e.target.value);
  }

  const handleCategoryDescChange = (e) => {
    setCatalogueDesName(e.target.value)
  }

  const handleBundlePrice = (e) => {
    setBundlePrice(e.target.value)
  }
  const handleOldBundlePrice = (e) => {
    setBundleOldPrice(e.target.value)
  }

  const handleLoyalityPoint = (e) => {
    setLoyalityPoint(e.target.value)
  }

  const handleradioChange = (e) => {
    e.persist();
    setProductType(e.target.value);
  }

  const validate = () => {
    if (productType?.length > 0 && loyalityPoint && bundleOldPrice
      && bundlePrice && catalogueDesName && catalogueName && imageName) {
      return false
    }
    return true;
  }
  const validateProduct = () => {
    if (productType?.length > 0 && categoryType && categoryType!=='Please select category' && catalogueDesName && catalogueName && imageName) {
      return false
    }
    return true;
  }

  const isSuperAdmin=()=>{
    return (user?.claims?.role==='admin') || (user?.claims?.role==='superAdmin')
  }

  return (
    <>

      <Modal show={showCatalogue} onHide={() => handleClosePopUp(data)} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Update Sub Category</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className='flex-row'>
            {/* <i class="bi bi-card-image" style={{fontSize:'130px',marginRight:'20px'}} alt='add image'></i> */}
            <ImageUpload url={data?.imageUrl} onFileSelect={onFileSelect} />
            <Form style={{ marginLeft: '10%' }}>
              <Form.Label style={{ marginTop: 15 }}>Product Type</Form.Label>

              <div key={`inline-${'radio'}`} className="mb-3">
                <Form.Check
                  inline
                  label="Product"
                  value='product'
                  name="group1"
                  type={'radio'}
                  id={`inline-${'radio'}-2`}
                  onChange={handleradioChange}
                  checked={productType === "product"}
                  defaultChecked={productType === "product"}
                />
                <Form.Check
                  inline
                  value='bundle'
                  label="Bundle"
                  type={'radio'}
                  id={`inline-${'radio'}-3`}
                  onChange={handleradioChange}
                  checked={productType === "bundle"}
                  defaultChecked={productType === "bundle"}
                />
              </div>

              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Category Type</Form.Label>
                <Form.Select aria-label="categoryname" size="lg" onChange={handleCategoryChange} value={categoryID}>
                  <option value="Please select category">Please select category</option>
                  {allCategory?.map((cat)=>{
                    return  <option value={cat?.id}>{cat?.categoryName}</option>
                  })}
               

                </Form.Select>

                <Form.Label style={{ marginTop: 15 }}>Product Sub Category Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter category name"
                  onChange={handleCategoryNameChange}
                  defaultValue={data?.itemName}
                />
                {productType === "bundle" ? <>


                  <Form.Label style={{ marginTop: 15 }}>Bundle Old Price</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter bundle old price"
                    onChange={handleOldBundlePrice}
                    defaultValue={data?.bundleOldPrice}
                  />

                  <Form.Label style={{ marginTop: 15 }}>Bundle New Price</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter bundle new price"
                    onChange={handleBundlePrice}
                    defaultValue={data?.bundleNewPrice}

                  />

                  <Form.Label style={{ marginTop: 15 }}>Loyality point (Percentage)</Form.Label>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Control
                      type="text"
                      placeholder="Enter Loyality point"
                      onChange={handleLoyalityPoint}
                      defaultValue={data?.loyalityPoint}
                    />
                    <span style={{ alignSelf: 'end' }}>Point to be added: {Math.round(Number(bundlePrice) * (Number(loyalityPoint) / 100))}</span>
                  </div>
                </> : <></>}



              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea"
                  defaultValue={data?.desc}
                  rows={4}
                  placeholder="Enter description" onChange={handleCategoryDescChange} />
              </Form.Group>
            </Form>

          </div>
          {loading ? <Loader loading={loading} /> : null}
          {showToast ? <ToastMessage url={image?.preview} title={catalogueName} /> : null}
        </Modal.Body>
        <Modal.Footer>
          {isSuperAdmin() && <Button variant="danger" onClick={handleDeleteCatalogue}>
            Delete
          </Button>
}
          <Button className='primary-button' onClick={handleUpdateCategory} disabled={productType === "product" ? validateProduct() : validate()}>
            Update Category
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateCategoryPopup;