import { GET_ACTIVE_ORDERS,UPDATE_ORDERS,GET_PENDING_ORDERS} from "../types";
import {getAPI,putAPI} from "../../services/apiService"
import {GET_ACTIVE_ORDERS_URL,UPDATE_ORDERS_URL} from "../../services/apiConstant"




export const getActiveOrderList = (orderStatus) => async (dispatch, getState) => {
  try {
    let url=GET_ACTIVE_ORDERS_URL
    if(orderStatus){
      url=url+"?orderStatus="+orderStatus
    }
    const orderResponse= await getAPI(url);
    console.log('OrderResponse-',orderResponse);
    dispatch({
      type: GET_ACTIVE_ORDERS,
      payload: orderResponse,
    });
  } catch (error) {
    console.log("Error", error);
  }
};


export const getRefundProceessdOrderList = (orderStatus,returnStatus) => async (dispatch, getState) => {
  try {
    let url=GET_ACTIVE_ORDERS_URL
    if(orderStatus){
      url=url+"?orderStatus="+orderStatus+"&statusName="+returnStatus
    }
    const orderResponse= await getAPI(url);
    console.log('OrderResponse-',orderResponse);
    dispatch({
      type: GET_ACTIVE_ORDERS,
      payload: orderResponse,
    });
  } catch (error) {
    console.log("Error", error);
  }
};



export const getPendingOrderList = (orderStatus) => async (dispatch, getState) => {
  try {
    let url=GET_ACTIVE_ORDERS_URL
    if(orderStatus){
      url=url+"?orderStatus="+orderStatus
    }
    const orderResponse= await getAPI(url);
    console.log('OrderResponse-',orderResponse);
    dispatch({
      type: GET_PENDING_ORDERS,
      payload: orderResponse,
    });
  } catch (error) {
    console.log("Error", error);
  }
};


export const updateOrder = (id,data) => async (dispatch, getState) => {
    try {
      const updateorderResponse= await putAPI(UPDATE_ORDERS_URL+id,data);
      dispatch({
        type: UPDATE_ORDERS,
        payload: updateorderResponse,
      });
    
    } catch (error) {
      console.log("Error", error);
    }
  };
  




