import {UPDATE_PRODUCT, CLEAR_UPDATE_PRODUCTS } from "../types";

const INITIAL_STATE = null;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT:
      return {
        ...action.payload,
      };
      
        case  CLEAR_UPDATE_PRODUCTS:
        return INITIAL_STATE;

    default:
      return INITIAL_STATE;
  }
};
