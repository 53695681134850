import React, { useState,useEffect } from 'react';
import './style.css';
import ImageTiles from '../../../component/imageTiles';
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import AddMore from '../../../assets/more.png'
import AddMainCategoryPopup from '../../products/component/AddMainCategoryPopup';
import { getCategoryList,updateCategory } from '../../../redux/actions/categoryAction';



const MainCategoryCard = ({ user,handlePageSelect }) => {


  const [allCategory,setAllCategory]=useState([])
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const[selectedData,setSelectedData]=useState();

  const categoryList = useSelector((state) => state.getCategoryReducer) || [];

  useEffect(() => {
  dispatch(getCategoryList());
}, [])

useEffect(() => {
  if(categoryList?.length>0){
    console.log('categoryList',categoryList)
    categoryList.push({
      "categoryName": 'Add More',
      "categoryType": 'addmore',
      "imageUrl": AddMore,
      "enable":true
    })
   setAllCategory(categoryList)
  }

}, [categoryList])



 

  const[showCategory,setShowCategory]=useState(false);

  const showProductList = (data) => {
    console.log('selected data', data);
    handlePageSelect('products', data);
  }


  const handleToggleChange = (data) => {
     dispatch(updateCategory(data));

    //alert(value)
  }



  const handleCloseCategory = (data) => {
    console.log('handleUpdateCatalogueShow data', data);
    setSelectedData(null);
    setShowCategory(false)
    dispatch(getCategoryList());
    // setSelectedItem(data)
    // setShowUpdateCatalogue(!showUpdateCatalogue)
    // if(showUpdateCatalogue){
    //    dispatch(getCatalogueList());
    //}
  }

const handleUpdateCatalogueShow=(selectedData)=>{
  setSelectedData(selectedData)
  setShowCategory(true)

}

const onClickAddMore=()=>{
  console.log('onClickAddMore');
  setShowCategory(true)
}

  return <div className='col-xxl-4 col-md-6'>
        <AddMainCategoryPopup showCategory={showCategory} handleCloseCategory={handleCloseCategory} selectedData={selectedData} user={user}/>

    <div class="graph-card ">
      <div class="card-body">
        <h5 class="card-title">{'Main Category'} <span>| {`Total: ${allCategory.length-1}`}</span></h5>
        <div>
          <div className='row'>
            {allCategory?.map((data, index) => {
              // return <ImageCard title={data.itemName}  icon={data.url} handleToggleChange={handleToggleChange} />
              return <ImageTiles
                key={`cardId${index}`}
                data={data}
                handleToggleChange={handleToggleChange}
                handleUpdateCatalogueShow={handleUpdateCatalogueShow}
                showProductList={showProductList}
                onClickAddMore={onClickAddMore}
              />

            })}
            {/* <IconCard  title={"Add New Category"} icon={addMoreIcon} onClick={handleCatalogueSow} /> */}
          </div>
        </div>

      </div>

    </div>

  </div>
}
export default MainCategoryCard