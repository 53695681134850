import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from 'react-bootstrap/Button';
import profileIcon from '../../assets/user.png'

import { getMemberList} from '../../redux/actions/memberAction';
import {DELETE_MEMBER_URL,ADD_MEMBER_URL} from "../../services/apiConstant"
import {deleteAPI,postAPI} from "../../services/apiService"

import './member.css';

import { Loader } from '../../component/loader';
import AddMemberPopup from './addMember';

const MemberList = (props) => {
    const dispatch = useDispatch();
    const [memberListArray,setmemberListArray]=useState([])
    const [loading, setLoading] = useState(false);
    const [showmemberPopup, setShowMemberPopup] = useState(false);
    
    const memberList = useSelector((state) => state.memberReducer);

    useEffect(() => {
      setLoading(true)
    dispatch(getMemberList());
  }, [])

  useEffect(() => {
    if(memberList){
      console.log('memberList-gun',memberList)
      setLoading(false)
      setmemberListArray(memberList)
    }

}, [memberList])

const addNewMember=()=>{
  setShowMemberPopup(true)
  }
  const handleDelete=async(item)=>{
    setLoading(true)

    const deleteMemberResponse= await deleteAPI(DELETE_MEMBER_URL+item.id);
    if(deleteMemberResponse){
      dispatch(getMemberList());
      setLoading(false)
    }

  }

  const togglePopup=()=>{
    setShowMemberPopup(!showmemberPopup)
    dispatch(getMemberList());

  }

  const isSuperAdmin=()=>{
    return (props?.user?.claims?.role==='admin') || (props?.user?.claims?.role==='superAdmin')
  }
    return <>
        <div class="product-card ">
        <div class="card-body">
        <div className='title-container'>
        <h5 class="card-title ">{'All Members'}  </h5>

        <h5 class="card-title ">{`Total (${memberListArray?.length})`}  </h5>
        { <Button onClick={addNewMember}
                    className='primary-button'
                    style={{ margin: "10px", padding: "10px", fontWeight: "700" }}>
                    <i class="bi bi-plus-square mr-5"></i> Add New Member
                  </Button>
}

        </div>
        {loading &&<Loader loading={loading}/>}
        {memberListArray.map((item,index)=>{
          return <div className='wrapper-user' key={"member"+index}>
          <div className='row-home mt-20'>
            <img src={profileIcon} alt="profile" width="100" height="100" className='online' />
            <div className='column-home ml-20'>
              <div className='name'> {item?.memberName}</div>
              <div><span>MemberId:</span> {item?.memberId}</div>
              <div><span>Name:</span> {item?.name}</div>
              <div><span>Registered Mobile Number:</span> {item?.mobileNumber || '----'}</div>
              <div><span>Referal Member Id:</span> {item?.referalMemberId}</div>
              <div><span>IsMember:</span> {item?.isMember?"Yes":"No"}</div>
            </div>
          </div>
          { isSuperAdmin() && <i class="bi bi-trash trash" onClick={() => handleDelete(item)}></i>}
        </div>  
        })}
    </div>
                  </div>
        {showmemberPopup && <AddMemberPopup show={showmemberPopup} togglePopup={togglePopup}/>}
    </>
}

export default MemberList;