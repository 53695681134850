import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ImageUpload from '../../../component/imageUpload';
import { useDispatch, useSelector } from "react-redux";
import { uploadImage, clear } from "../../../redux/actions/uploadImageAction";
import { addCatalogue } from '../../../redux/actions/catalogueAction';
import { Loader } from '../../../component/loader';
import ToastMessage from '../../../component/toast'
import { getCategoryList } from '../../../redux/actions/categoryAction';

function AddCategoryPopup({ showCatalogue, handleCloseCatalogue }) {

  const [image, setImage] = useState({ preview: "", raw: "" });
  const [imageName, setImageName] = useState('');

  const [categoryType, setCategoryType] = useState('');
  const [catalogueName, setCatalogueName] = useState('');
  const [catalogueDesName, setCatalogueDesName] = useState('');
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [bundlePrice, setBundlePrice] = useState('');
  const [loyalityPoint, setLoyalityPoint] = useState('');
  const [productType, setProductType] = useState('');
  const [bundleOldPrice, setBundleOldPrice] = useState('');
  const [allCategory,setAllCategory]=useState([])



  const dispatch = useDispatch();
  
  const categoryList = useSelector((state) => state.getCategoryReducer) || [];
  useEffect(() => {
    dispatch(getCategoryList());
  }, [])
  
  useEffect(() => {
    if(categoryList?.length>0){
      console.log('categoryList--',categoryList)
    //   categoryList.push({
    //     "categoryName": 'Add More',
    //     "categoryType": 'addmore',
    //     "imageUrl": AddMore,
    //     "enable":true
    //   })
      setAllCategory(categoryList)
    }
  
  }, [categoryList])

  useEffect(() => {
    //make sure redux is clear on firt time page load
    dispatch(clear());
    clearAllField()
  }, [])

  const uploadFileReponse = useSelector((state) => state.uploadFileReducer);
  console.log('uploadFileReponse', uploadFileReponse)

  useEffect(() => {
    // console.log('useEffect uploadFileReponse',uploadFileReponse)
    if (uploadFileReponse && Object.keys(uploadFileReponse).length > 0) {
      const imageUrl = uploadFileReponse.imagePublicUrl;
      if (imageUrl && catalogueName) {
        const data = {
          "imageName": imageName,
          "imageUrl": imageUrl,
          "itemCode": "catalogue",
          "mainCategoryId":categoryType,
          "categoryType": getCategoryTypeValue(categoryType),
          "desc": catalogueDesName,
          "itemType": catalogueName,
          "itemName": catalogueName,
          "bundleOldPrice": bundleOldPrice,
          "bundleNewPrice": bundlePrice,
          "enable": true,
          "productType": productType,
          "loyalityPointValue": Math.round(Number(bundlePrice) * (Number(loyalityPoint) / 100)),
          "loyalityPoint": loyalityPoint,
          "lastUpdatedTime":Date.now()

        }

        dispatch(addCatalogue(data));
        setLoading(false)
        setShowToast(true)
        // handleCloseCatalogue();
        setTimeout(hideAllView, 2000);
      }
    }
  }, [uploadFileReponse])


  const getCategoryTypeValue=(categoryId)=>{
  const filterCategory=allCategory.find((ac)=>ac.id===categoryId)
  return filterCategory.categoryName

  }

  const hideAllView = () => {
    clearAllField()
    handleCloseCatalogue();
    setShowToast(false)
  }


  const onFileSelect = (selectedImage) => {
    setImage(selectedImage);
    console.log('selectedImage', selectedImage);
    const imageName = `${new Date().getTime()}_${selectedImage.raw.name}`;
    setImageName(imageName)
  }

  const handleAddCategory = () => {
    setLoading(true)
    const formData = new FormData();
    formData.append("file", image.raw);
    formData.append("fileName", imageName);
    formData.append('bucketName', 'dhansheera')
    formData.append('folderName', 'ProductCategory/catalogue')
    dispatch(uploadImage(formData))
  }

  const handleCategoryChange = (e) => {
    console.log(e.target.value)
    setCategoryType(e.target.value)
  }

  const handleCategoryNameChange = (e) => {
    setCatalogueName(e.target.value);
  }

  const handleCategoryDescChange = (e) => {
    setCatalogueDesName(e.target.value)
  }

  const handleBundlePrice = (e) => {
    setBundlePrice(e.target.value)
  }
  const handleLoyalityPoint = (e) => {
    setLoyalityPoint(e.target.value)
  }

  const handleOldBundlePrice = (e) => {
    setBundleOldPrice(e.target.value)
  }
  const handleradioChange = (e) => {
    e.persist();
    setProductType(e.target.value);
  }

  const validate = () => {
    if (productType.length > 0 && loyalityPoint && bundleOldPrice
      && bundlePrice && catalogueDesName && catalogueName && imageName) {
      return false
    }
    return true;
  }

  const validateProduct = () => {
    if (productType.length > 0 && categoryType &&  catalogueDesName && catalogueName && imageName) {
      return false
    }
    return true;
  }
  const clearAllField = () => {
    setCatalogueName('')
    setCatalogueDesName('');
    setBundlePrice('');
    setCategoryType('')
    setBundleOldPrice('');
    setLoyalityPoint(0);
    setProductType('');
    setImageName('')
  }

  return (
    <>

      <Modal show={showCatalogue} onHide={handleCloseCatalogue} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Add New Category</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className='flex-row'>
            {/* <i class="bi bi-card-image" style={{fontSize:'130px',marginRight:'20px'}} alt='add image'></i> */}
            <ImageUpload onFileSelect={onFileSelect} />
            <Form style={{ marginLeft: '10%' }}>
              <Form.Group className="mb-3" controlId="catalogue.ControlInput1">
                <Form.Label style={{ marginTop: 15 }}>Product Type</Form.Label>

                <div key={`inline-${'radio'}`} className="mb-3">
                  <Form.Check
                    inline
                    label="Product"
                    value='product'
                    name="group1"
                    type={'radio'}
                    id={`inline-${'radio'}-2`}
                    onChange={handleradioChange}
                    checked={productType === "product"}
                  />
                  <Form.Check
                    inline
                    value='bundle'
                    label="Bundle"
                    type={'radio'}
                    id={`inline-${'radio'}-3`}
                    onChange={handleradioChange}
                    checked={productType === "bundle"}
                  />
                </div>
                <Form.Label>Category Type</Form.Label>
                <Form.Select aria-label="Default select example" size="lg" onChange={handleCategoryChange}>
                  <option>Please select category</option>
                  {allCategory?.map((cat)=>{
                    return  <option value={cat?.id}>{cat?.categoryName}</option>
                  })}
               
                </Form.Select>

                <Form.Label style={{ marginTop: 15 }}>Product Sub Category Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter category name"
                  onChange={handleCategoryNameChange}
                />
               {productType === "bundle" ?<>
                <Form.Label style={{ marginTop: 15 }}>Bundle Old Price</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter bundle old price"
                  onChange={handleOldBundlePrice}
                />

                <Form.Label style={{ marginTop: 15 }}>Bundle New Price</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter bundle new price"
                  onChange={handleBundlePrice}
                />
              

                <Form.Label style={{ marginTop: 15 }}>Loyality point (Percentage)</Form.Label>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Control
                    type="text"
                    placeholder="Enter Loyality point"
                    onChange={handleLoyalityPoint}
                  />
                  <span style={{ alignSelf: 'end' }}>Point to be added: {Math.round(Number(bundlePrice) * (Number(loyalityPoint) / 100))}</span>
                </div>

                </>:<></>}

              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="catalogue.ControlTextarea1"
              >
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea"
                  rows={4}
                  placeholder="Enter description"
                  onChange={handleCategoryDescChange} />
              </Form.Group>
                          
            </Form>

          </div>
          {loading ? <Loader loading={loading} /> : null}
          {showToast ? <ToastMessage url={image.preview} title={catalogueName} /> : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCatalogue}>
            Close
          </Button>
          <Button className={'primary-button'} onClick={handleAddCategory} disabled={productType === "product"?validateProduct():validate()}>
            Add Category
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddCategoryPopup;