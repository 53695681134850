import React, { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import courierIcon from '../../assets/courier_icon.png'
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from "react-redux";
import { getCourierList } from '../../redux/actions/courierAction';
import { Loader } from '../../component/loader';
import { createOrder } from '../../services/shipRocketApi'; 
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder"

function AssignOrderPopup(props) {

      const dispatch = useDispatch();
      const [selectedItem, setSelectedItem] = useState(null);
      const [loading, setLoading] = useState(false);
      const [courierResponseList, setCourierResponseList] = useState([]);
      const[showNext,setShowNext]=useState(false);

      const[picupAddressName,setPicupAddressName]=useState("");

      const[pickupPinCode,setpickupPinCode]=useState("");
      const[weight,setWeight]=useState("");


      const courierList = useSelector((state) => state.courierReducer);



    //   useEffect(() => {
    //     console.log('props.selectedOrder',props.selectedOrder)
    //     const data={
    //         "fromPostalCode":pickupPinCode,
    //         "toPostalCode":props.selectedOrder?.deleiveryDetails?.selectedAddress?.pincode,
    //         "weight":weight || '0.5',
    //         "cod":0

    //     }

    //     setLoading(true)
    //     dispatch(getCourierList(data));
    //   // getCourierListDirect(data)
    //   }, [props.selectedOrder])
      

      useEffect(() => {
        if(courierList){
            setCourierResponseList(courierList?.data?.available_courier_companies || [])
            setLoading(false)
        }
   
      }, [courierList])

      const loadCourierCompanyList=()=>{
        setShowNext(true)
        const data={
            "fromPostalCode":pickupPinCode,
            "toPostalCode":props.selectedOrder?.deleiveryDetails?.selectedAddress?.pincode,
            "weight":weight || '0.5',
            "cod":props.selectedOrder?.paymentInfo?.paymentChannel==='cod'? 1: 0
        }
        setLoading(true)
        dispatch(getCourierList(data));
      }

   
      const handleSelect = (item) => {
        setSelectedItem(item);
      };
    
      const handleSubmit = async() => {
        if(picupAddressName && selectedItem){
            const submitOrder=await createOrder(createOrderBody());
            if(submitOrder){
                props.onConfirmSubmitAssign(props.selectedOrder,submitOrder)
            }
        }
        else{
            alert('Please select courier partner')
        }
        //onSelect(selectedItem);
       
      };

   

      const createOrderBody=()=>{
        console.log('selectedItem',props.selectedOrder)
        const addressData=props.selectedOrder?.deleiveryDetails?.selectedAddress;
        return {
            "order_id": props.selectedOrder?.orderId, 
            "order_date": props.selectedOrder?.orderDate,
            "pickup_location": picupAddressName || "Primary",
            "channel_id": "",
            "company_name":"Dhansheera Ecommerce Pvt Ltd",
            "comment": "dhansheera mobile app order",
            "billing_customer_name": addressData?.fullName,
            "billing_last_name": "",
            "billing_address": addressData?.addressLine1,
            "billing_address_2": addressData?.addressLine2,
            "billing_city": addressData?.city,
            "billing_pincode": addressData?.pincode,
            "billing_state": addressData?.state,
            "billing_country": "India",
            "billing_email": "",
            "billing_phone": addressData?.mobileNumber,
            "shipping_is_billing": true,
            "shipping_customer_name":  addressData?.fullName,
            "shipping_last_name": "",
            "shipping_address": addressData?.addressLine1,
            "shipping_address_2": addressData?.addressLine2,
            "shipping_city": "",
            "shipping_pincode": addressData?.pincode,
            "shipping_country": "INDIA",
            "shipping_state": addressData?.state,
            "shipping_email": "",
            "shipping_phone": addressData?.mobileNumber,
            "order_items": getOrderObject(),
            "payment_method": props.selectedOrder?.paymentInfo?.paymentChannel === 'cod' ?"COD":"Prepaid",
            "shipping_charges": 0,
            "giftwrap_charges": 0,
            "transaction_charges": 0,
            "total_discount": 0,
            "sub_total": props.selectedOrder?.paymentInfo?.totalPayment,
            "length": 10,
            "breadth": 15,
            "height": 20,
            "weight": props.selectedOrder.weight || weight
          }
      }

  const getOrderObject=()=>{
    const orderList=[]
    props.selectedOrder?.orders.map((ordr)=>{
        const product=ordr.productDetails
        const order={
            "name": product.itemName,
            "sku": ordr.productId,
            "units": product.quantity,
            "selling_price": product.currentPrice,
            "discount": Number(product.oldPrice)-Number(product.currentPrice),
            "tax": "",
            "hsn": ''
        }
        orderList.push(order);

    })
    return orderList;
  }


      const GenrateStar = ({activeStars}) => {
        return (
          <Box>
            {[...new Array(5)].map((arr, index) => {
              return index < activeStars ? <StarIcon /> : <StarBorderIcon />;
            })}
          </Box>
        );
      };

      const handlePicupAddressChange=(e)=>{
        setPicupAddressName(e.target.value)
      }

      const handlepickupPinCode=(e)=>{
        setpickupPinCode(e.target.value)
      }

      const handleWeight=(e)=>{
        setWeight(e.target.value)
      }


    

  return (
    <div
      className="modal show"
      style={{ display: 'block'}}
    >
      <Modal show={true} onHide={() => props.onCloseSubmitAssign} size='lg' style={{
       width: '100%',
    }}>
        <Modal.Header closeButton onClick={props.onCloseSubmitAssign}>
          <Modal.Title>{!showNext?"Enter Courier Partner Details":"Select Courier Partner"}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            
        {loading && <Loader loading={loading} />}
                  <div>
                      {showNext ?  
                          courierResponseList.map((item) => (
                              <div key={item.id} onClick={() => handleSelect(item)} className='flex-row list-partner'>
                                  <Form.Check
                                      inline
                                      value='bundle'
                                      type={'radio'}
                                      id={`inline-${'radio'}-3`}
                                      onChange={() => handleSelect(item)}
                                      checked={selectedItem?.id === item.id}
                                  />
                                  <img src={courierIcon} alt={item.courier_name} style={{ width: 80, height: 80 }} />
                                  <div className='ml-20'>
                                      <div className='bold-font-small' style={{ fontSize: 22 }}>{item.courier_name}</div>
                                      <div className='flex-row'>
                                          <span className='bold-font-small mr-10'>Delivery Charge:</span>
                                          <span> Rs.{item.freight_charge}</span>
                                      </div>
                                      <div className='flex-row'>
                                          <span className='bold-font-small mr-10'>Estimated Delivery Time:</span>
                                          <span> {item.estimated_delivery_days} days</span>
                                      </div>

                                      <div className='flex-row'>
                                          <span className='bold-font-small mr-10'>Estimated Delivery Date:</span>
                                          <span> {item.etd}</span>
                                      </div>

                                      <div className='flex-row'>
                                          <span className='bold-font-small mr-10'>Cut off Time:</span>
                                          <span> {item.cutoff_time}</span>
                                      </div>

                                      <div className='flex-row'>
                                          <span className='bold-font-small mr-10'>Rating:</span>
                                          <span><GenrateStar activeStars={Number(item.delivery_performance)} /></span>
                                      </div>
                                  </div>

                              </div>
                          ))
                          :
                          <Form.Group>
                          <Form.Label style={{ marginTop: 15 }}>Enter Pickup NickName From ShipRocket</Form.Label>
                           <Form.Control
                             type="text"
                             placeholder="Enter Pickup Nick Name eg: Primary"
                             onChange={handlePicupAddressChange}
                             defaultValue={picupAddressName}
           
                           />
           
                          <Form.Control
                             type="text"
                             placeholder="Enter Pickup Address PinCode"
                             onChange={handlepickupPinCode}
                             defaultValue={pickupPinCode}
           
                           />
                        <Form.Control
                             type="text"
                             placeholder="Enter Weight"
                             onChange={handleWeight}
                             defaultValue={weight}
           
                           />
           
                           </Form.Group>


                      }
                  </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={props.onCloseSubmitAssign}>Close</Button>
         {showNext && <Button variant="primary" onClick={()=>setShowNext(false)}>Back</Button>}
          <Button variant="primary" onClick={()=>!showNext? loadCourierCompanyList():handleSubmit()} disabled={!weight && !picupAddressName && !pickupPinCode}>
            {!showNext?"Next":"Send Order to Courier"}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AssignOrderPopup;