import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import Button from 'react-bootstrap/Button';

import { getOfferList} from '../../redux/actions/offerListAction';
import {DELETE_OFFER_URL} from "../../services/apiConstant"
import {deleteAPI} from "../../services/apiService"

import './offer.css';

import { Loader } from '../../component/loader';
import AddOffer from './addOffer';

const OfferPage = (props) => {
    const FOLDER_PATH = 'mobile/offers'

    const dispatch = useDispatch();
    const [offerListArray,setOfferListArray]=useState([])
    const [loading, setLoading] = useState(false);
    const [showOfferPopup, setshowOfferPopup] = useState(false);
    const offerList = useSelector((state) => state.offerListReducer);

    useEffect(() => {
      setLoading(true)
    dispatch(getOfferList());
  }, [])

  useEffect(() => {
    if(offerList){
      setLoading(false)
      setOfferListArray(offerList)
    }

}, [offerList])

const addNewOffer=()=>{
  setshowOfferPopup(true)
  }
  const handleDelete=async(item)=>{
    setLoading(true)
    const body = { "imagePath": `${FOLDER_PATH}/${item?.imageName}` }
    const deleteOfferResponse= await deleteAPI(DELETE_OFFER_URL+"/"+item.id,body);
    if(deleteOfferResponse){
      dispatch(getOfferList());
      setLoading(false)
    }

  }

  const togglePopup=()=>{
    setshowOfferPopup(!showOfferPopup)
    setTimeout(()=>{
    dispatch(getOfferList());
    },1500)

  }

  const isSuperAdmin=()=>{
  console.log('props?.user?.customClaims?.role',props?.user)
    return (props?.user?.claims?.role==='admin') || (props?.user?.claims?.role==='superAdmin')
  }
    return <>
        <div class="product-card ">
        <div class="card-body">
        <div className='title-container'>
        <h5 class="card-title ">{'All Offers'}  </h5>

        <h5 class="card-title ">{`Total (${offerListArray?.length})`}  </h5>
        {isSuperAdmin() && <Button onClick={addNewOffer}
                    className='primary-button'
                    style={{ margin: "10px", padding: "10px", fontWeight: "700" }}>
                    <i class="bi bi-plus-square mr-5"></i> Add New Offer
                  </Button>
}

        </div>
        {loading &&<Loader loading={loading}/>}
        {offerListArray.length===0 && <center>No offers added</center>}
        {offerListArray.map((item,index)=>{
          return <div className='wrapper-user' key={"user"+index}>
          <div className='row-home mt-20'>
            <img src={item?.imageUrl} alt="profile" width="300" height="150"  />
            <div className='column-home ml-20'>
             <div><span>Category:</span> {item?.categoryType}</div>
              <div><span>Page Name:</span> {item?.pageName || '----'}</div>
              <div><span>Sequence:</span> {item?.sequence}</div>
              <div><span>Uploaded Time:</span> { new Date(item?.dateTime).toDateString()}</div>

            </div>
          </div>
          { isSuperAdmin() && <i class="bi bi-trash trash" onClick={() => handleDelete(item)}></i>}
        </div>  
        })}
    </div>
                  </div>
        {showOfferPopup && <AddOffer show={showOfferPopup} togglePopup={togglePopup}/>}
    </>
}

export default OfferPage;