import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import Button from 'react-bootstrap/Button';
import profileIcon from '../../assets/user.png'

import { getAdminUserList} from '../../redux/actions/adminUsersAction';
import {DELETE_ADMIN_USER_URL,ADD_ADMIN_USER_URL} from "../../services/apiConstant"
import {deleteAPI,postAPI} from "../../services/apiService"

import './users.css';

import { Loader } from '../../component/loader';
import AddAdminUserPopup from './addAdminUser';

const UserList = (props) => {
    const dispatch = useDispatch();
    const [userListArray,setuserListArray]=useState([])
    const [loading, setLoading] = useState(false);
    const [showAdminPopup, setShowAdminPopup] = useState(false);
    const userList = useSelector((state) => state.adminUsersReducer);

    useEffect(() => {
      setLoading(true)
    dispatch(getAdminUserList());
  }, [])

  useEffect(() => {
    if(userList){
      console.log('userList-gun',userList)
      setLoading(false)
      setuserListArray(userList)
    }

}, [userList])

const addNewUser=()=>{
  setShowAdminPopup(true)
  }
  const handleDelete=async(item)=>{
    setLoading(true)
    const body={
      uid:item.uid,
      adminUid:''
    }
    const deleteUserResponse= await deleteAPI(DELETE_ADMIN_USER_URL,body);
    if(deleteUserResponse){
      dispatch(getAdminUserList());
      setLoading(false)
    }

  }

  const togglePopup=()=>{
    setShowAdminPopup(!showAdminPopup)
    dispatch(getAdminUserList());

  }

  const isSuperAdmin=()=>{
  console.log('props?.user?.customClaims?.role',props?.user)
    return (props?.user?.claims?.role==='admin') || (props?.user?.claims?.role==='superAdmin')
  }
    return <>
        <div class="product-card ">
        <div class="card-body">
        <div className='title-container'>
        <h5 class="card-title ">{'All Users'}  </h5>

        <h5 class="card-title ">{`Total (${userListArray?.length})`}  </h5>
        {isSuperAdmin() && <Button onClick={addNewUser}
                    className='primary-button'
                    style={{ margin: "10px", padding: "10px", fontWeight: "700" }}>
                    <i class="bi bi-plus-square mr-5"></i> Add New User
                  </Button>
}

        </div>
        {loading &&<Loader loading={loading}/>}
        {userListArray.map((item,index)=>{
          return <div className='wrapper-user' key={"user"+index}>
          <div className='row-home mt-20'>
            <img src={profileIcon} alt="profile" width="100" height="100" className='online' />
            <div className='column-home ml-20'>
              <div className='name'>{item?.displayName}</div>
              <div><span>Contact Number:</span> {item?.phoneNumber || '----'}</div>
              <div><span>Email:</span> {item?.email}</div>
              <div><span>Role:</span> {item?.customClaims?.role}</div>
            </div>
          </div>
          { isSuperAdmin() && <i class="bi bi-trash trash" onClick={() => handleDelete(item)}></i>}
        </div>  
        })}
    </div>
                  </div>
        {showAdminPopup && <AddAdminUserPopup show={showAdminPopup} togglePopup={togglePopup}/>}
    </>
}

export default UserList;