import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ImageUpload from '../../../component/imageUpload';
import { useDispatch, useSelector } from "react-redux";
import { uploadImage, clear } from "../../../redux/actions/uploadImageAction";
import { addCategory,deleteCategory,updateCategory } from '../../../redux/actions/categoryAction';
import { Loader } from '../../../component/loader';
import ToastMessage from '../../../component/toast'

function AddMainCategoryPopup({ showCategory, handleCloseCategory,selectedData,user }) {
  console.log('selectedData', selectedData)
  const FOLDER_PATH = 'MainCategory'

  const [image, setImage] = useState({ preview: "", raw: "" });
  const [imageName, setImageName] = useState(selectedData?.imageName);

  const [catalogueName, setCatalogueName] = useState(selectedData?.categoryName);
  const [catalogueDesName, setCatalogueDesName] = useState(selectedData?.desc);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const dispatch = useDispatch();
  const deleteFileReponse = useSelector((state) => state.deleteCategoryReducer);
  const currentUser = useSelector((state) => state.userReducer);


  useEffect(() => {
    //make sure redux is clear on firt time page load
    
    dispatch(clear());
   // clearAllField()
   setCatalogueName(selectedData?.categoryName)
  }, [])

  useEffect(() => {
   setCatalogueName(selectedData?.categoryName)
   setCatalogueDesName(selectedData?.desc)
  }, [selectedData])

  const uploadFileReponse = useSelector((state) => state.uploadFileReducer);
  console.log('currentUser--', currentUser)

  useEffect(() => {
     console.log('useEffect uploadFileReponse',uploadFileReponse)
     console.log('useEffect catalogueName',catalogueName)

    if (uploadFileReponse && Object.keys(uploadFileReponse).length > 0) {
      const imageUrl = uploadFileReponse.imagePublicUrl;
      if (imageUrl) {
        saveData(imageUrl);
      }
    }
  }, [uploadFileReponse])

  useEffect(() => {
    setLoading(false)
    if (deleteFileReponse && Object.keys(deleteFileReponse).length > 0) {
      handleCloseCategory(selectedData)
    }
  }, [deleteFileReponse])

  const saveData=(imageUrl)=>{
    const data = {
      "imageName": imageName || selectedData?.imageName ,
      "imageUrl": imageUrl || selectedData?.imageUrl,
      "itemCode": "mainCategory",
      "categoryName": catalogueName || selectedData?.categoryName,
      "desc": catalogueDesName || selectedData?.desc,
      "enable": true,
      "lastUpdatedTime":Date.now()

    }

    if(selectedData?.categoryName){
      data.id=selectedData?.id
      dispatch(updateCategory(data));
    }
    else{
    dispatch(addCategory(data));
    }
    setLoading(false)
    setShowToast(true)
    setTimeout(hideAllView, 2000);
  }


  const hideAllView = () => {
    clearAllField()
    handleCloseCategory(selectedData);
    setShowToast(false)
  }


  const onFileSelect = (selectedImage) => {
    setImage(selectedImage);
    console.log('selectedImage', selectedImage);
    const imageName = `${new Date().getTime()}_${selectedImage.raw.name}`;
    setImageName(imageName)
  }

  const handleAddCategory = () => {
    setLoading(true)
    if(selectedData?.imageName && !image.raw){
      saveData();
    }
    else{
    const formData = new FormData();
    formData.append("file", image.raw);
    formData.append("fileName", imageName);
    formData.append('bucketName', 'dhansheera')
    formData.append('folderName', FOLDER_PATH)
    dispatch(uploadImage(formData))
    }
  }

  const handleCategoryNameChange = (e) => {
    setCatalogueName(e.target.value);
  }

  const handleCategoryDescChange = (e) => {
    setCatalogueDesName(e.target.value)
  }

  const validate = () => {
    if ((selectedData?.imageName || image.raw) && catalogueName && catalogueDesName) {
      return false
    }
    return true;
  }

  const clearAllField = () => {
    setCatalogueName('')
    setCatalogueDesName('');
    setImageName('')
  }


  const isSuperAdmin=()=>{
    return (user?.claims?.role==='admin') || (user?.claims?.role==='superAdmin')
  }



  const handleDeleteCategory = () => {
    setLoading(true)
    const body = { "imagePath": `${FOLDER_PATH}/${selectedData?.imageName}` }
    dispatch(deleteCategory(selectedData?.id, body))
  }



  return (
    <>

      <Modal show={showCategory} onHide={()=>handleCloseCategory(selectedData)} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{selectedData?"Update Main Category":"Add Main Category"}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className='flex-row'>
            {/* <i class="bi bi-card-image" style={{fontSize:'130px',marginRight:'20px'}} alt='add image'></i> */}
            <ImageUpload onFileSelect={onFileSelect} url={selectedData?.imageUrl}/>
            <Form style={{ marginLeft: '10%' }}>
              <Form.Group className="mb-3" controlId="catalogue.ControlInput1">


                <Form.Label style={{ marginTop: 15 }}>Enter Category Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter category name"
                  onChange={handleCategoryNameChange}
                  defaultValue={selectedData?.categoryName}

                />


              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="catalogue.ControlTextarea1"
              >
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea"
                  rows={4}
                  placeholder="Enter description"
                  defaultValue={selectedData?.desc}
                  onChange={handleCategoryDescChange} />
              </Form.Group>
                          
            </Form>

          </div>
          {loading ? <Loader loading={loading} /> : null}
          {showToast ? <ToastMessage url={image?.preview} title={catalogueName} /> : null}
        </Modal.Body>
        <Modal.Footer>
       {(selectedData && isSuperAdmin()) ? <Button variant="danger" onClick={handleDeleteCategory}>
            Delete
          </Button>
          :<Button variant="secondary" onClick={handleCloseCategory} style={{ marginRight: 10 }}>
                Close
              </Button>
}
          <Button className={'primary-button'}  onClick={handleAddCategory} disabled={validate()}>
            {selectedData?.imageName ?"Update Category":"Add Category"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddMainCategoryPopup;