import { GET_OFFER_LIST} from "../types";
import {getAPI} from "../../services/apiService"
import {GET_OFFER_LIST_URL} from "../../services/apiConstant"


export const getOfferList = () => async (dispatch, getState) => {
  try {
    const offerListResponse= await getAPI(GET_OFFER_LIST_URL);
    console.log('offerListResponse-',offerListResponse);
    dispatch({
      type: GET_OFFER_LIST,
      payload: offerListResponse,
    });
  } catch (error) {
    console.log("Error", error);
  }
};







