import React, { useState ,useEffect} from 'react';
import { useNavigate } from "react-router-dom"
import './home.css';
import { HomeBanner } from '../../component/banner'
import SideBar from './component/sidebar'
import DashBoard from './component/dasboard'
import Catalogue from '../products/catalogue'
import ProductList from '../products/productList';
import OrderList from '../orders/orderList'
import RiderList from '../riders/riderList';
import UserList from '../users/userList';
import firebase from 'firebase/compat/app';
import OfferPage from '../offers/offerPage';
import MemberList from '../members/memberList';
import RefundList from '../refund/refundList';

import NotificationManager from '../notification/notificationManager';

const Home = () => {
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState('dashboard');
  const [selectedPageData, setSelectedPageData] = useState('dashboard');
  const [user,setUser]=useState()
  
  useEffect(()=>{
    firebase?.auth()?.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;
        console.log('currentUserDe',user)
        setUser(user)
        fetchCustomClaims()
        // 👈 Your code that needs an active user goes here
      } else {
        // User is signed out
        // ...
        navigate("/")

      }
    });

  },[])

  const fetchCustomClaims=()=>{
    firebase.auth().currentUser.getIdTokenResult()
    .then((user) => {
      setUser(user)
  })
  .catch((error) => {
    console.log(error);
  });
  }
  
 

  const handlePageSelect=(page,data)=>{
    setSelectedPage(page)
    setSelectedPageData(data)
  }

  const LoadPageBySelection = () => {
    switch (selectedPage) {
      case 'dashboard':
        return <DashBoard handlePageSelect={handlePageSelect} user={user}/>
      case 'products':
        return <Catalogue handlePageSelect={handlePageSelect} data={selectedPageData} user={user}/>
        case 'orders':
          return <OrderList handlePageSelect={handlePageSelect} user={user}/>
        case 'productList':
          return <ProductList data={selectedPageData} user={user}/>
          case 'riders':
            return <RiderList data={selectedPageData} user={user}/>
            case 'register':
              return <UserList data={selectedPageData} user={user}/>
              case 'member':
                return <MemberList data={selectedPageData} user={user}/>
              case 'offers':
              return <OfferPage data={selectedPageData} user={user}/>
              case 'notifications':
                return <NotificationManager data={selectedPageData} user={user}/>
                case 'refund':
                  return <RefundList data={selectedPageData} user={user}/>
    }
  }

  return (
    <>
      <HomeBanner user={user} />
      <SideBar onSelect={handlePageSelect} selectedPage={selectedPage} user={user}/>
      <div className='main_container'>
        <LoadPageBySelection />
      </div>
    </>
  )
};

export default Home;