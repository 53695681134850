import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import {UPLOAD_IMAGE_URL} from '../services/apiConstant'

export default function ImageUpload({url,onFileSelect}) {
  const [image, setImage] = useState({ preview: url || "", raw: "" });

  const handleChange = e => {
    if (e.target.files.length) {
      const fileData={
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      }
      onFileSelect(fileData)
      setImage(fileData);
    }
  };

  // const handleUpload = async e => {
  //   e.preventDefault();
    // const formData = new FormData();
    // formData.append("file", image.raw);
    // formData.append('bucketName','kiranoz')
    // formData.append('folderName','ProductCategory/catalogue')

  //   const response=await fetch(UPLOAD_IMAGE_URL, {
  //     method: "POST",
  //     body: formData
  //   });
  //   const responseData=await response.json()
  //   console.log('responseData',responseData);

  // };

  return (
    <div>
      <label htmlFor="upload-button">
        
      <div className="flex-column" style={{marginRight:'20px'}}>

        {image.preview ? <img src={image?.preview} alt="dummy" width="170" height="150" /> : 
          <>
            <i class="bi bi-card-image"  alt='add image' style={{fontSize:'130px',height:'150px'}}></i> 
          </>
        
        }
           {/* <Button variant="secondary" onClick={handleUpload} style={{marginTop:'20px'}}>Upload</Button> */}

        </div>

      </label>
      
      <input
        type="file"
        id="upload-button"
        style={{ display: "none" }}
        onChange={handleChange}
      />
    </div>
  );
}
