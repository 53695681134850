
import React, { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';
import { ReturnStatus,OrderStatus } from './OrderEnum';

function OrderItemPopup (props)  {
    
    return (<>
        <Modal show={true} onHide={props.onCloseProductItemPopup} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title> {"Order List - Total Item ("+props.data.orders.length+")"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
            {props.data.orders?.map((item,index)=>{
          return <div className='wrapper-user' key={"user"+index}>
          <div className='row-home mt-20'>
            <img src={item?.productDetails?.imageUrl} alt="item" width="100" height="120" />
            <div className='column-home ml-20'>
              <div className='prd-name'>{item?.productDetails?.itemName}</div>
              <div><span>Quantity:</span> {item?.quantity}</div>
              <div><span>Category:</span> {item?.productDetails?.itemType}</div>
              {item?.productDetails?.size && <div><span>Size:</span> {item?.productDetails?.size}</div>}

              <div><span>Price Per Unit:</span> {item.productDetails?.currentPrice}</div>
              {item.productDetails.trackInfoUrl && <div style={{wordWrap:'break-word',maxWidth:'550px'}}><span>TrackUrl:</span><a href={item.productDetails.trackInfoUrl} target='_blank'> {item.productDetails.trackInfoUrl}</a></div>}

            </div>
          </div>
        </div>  
        })}
            </Modal.Body>
            <Modal.Footer>
                <div className='flex-row justify'>
                    <div className='flex-row' style={{display:'contents'}}>
                        <Button variant="secondary" onClick={props.onCloseProductItemPopup} style={{ marginRight: 10 }}>
                            Close
                        </Button>
                        <div style={{alignSelf:'center',fontWeight:'600'}}>
                            <span>Total Amount:</span> Rs. {props.data?.orderStatus?.toLowerCase()===OrderStatus.RETURN?props.data?.totalRefundAmount:props.data?.paymentInfo?.totalPayment}
                            </div>

                       
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    </>)



}

export default OrderItemPopup