import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ImageUpload from '../../component/imageUpload';
import { useDispatch, useSelector } from "react-redux";
import { uploadImage, clear } from "../../redux/actions/uploadImageAction";
import { Loader } from '../../component/loader';
import ToastMessage from '../../component/toast'
import { postAPI } from "../../services/apiService"
import { SEND_NOTIFICATION_URL } from "../../services/apiConstant"

function NotificationManager({ user }) {
  const FOLDER_PATH = 'Notification'

  const [imageUrl, setImageUrl] = useState();
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [imageName, setImageName] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.userReducer);
  const uploadFileReponse = useSelector((state) => state.uploadFileReducer);


  useEffect(() => {

  }, [])


  useEffect(() => {

    if (uploadFileReponse && Object.keys(uploadFileReponse).length > 0) {
      const imageUrl = uploadFileReponse.imagePublicUrl;
      if (imageUrl) {
        sendFinalNotication(imageUrl);
      }
    }
  }, [uploadFileReponse])

  const sendNotificationProcess = () => {
    setLoading(true)
    if(imageUrl){
      sendFinalNotication(imageUrl)
    }
    else{
      const formData = new FormData();
      formData.append("file", image.raw);
      formData.append("fileName", imageName);
      formData.append('bucketName', 'dhansheera')
      formData.append('folderName', FOLDER_PATH)
      dispatch(uploadImage(formData))
    }
 
  }

  const sendFinalNotication = async (uploadedImageUrl) => {
    const data = {
      "title": title,
      "imageUrl": uploadedImageUrl || imageUrl,
      "desc": description,
    }
    const sendNotificationDataResponse = await postAPI(SEND_NOTIFICATION_URL, data);
    clearAllField();
    setLoading(false)


  }


  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  }

  const handleDescChange = (e) => {
    setDescription(e.target.value)
  }

  const handleImageUrlChange = (e) => {
    setImageUrl(e.target.value)
  }

  const onFileSelect = (selectedImage) => {
    setImage(selectedImage);
    console.log('selectedImage', selectedImage);
    const imageName = `${new Date().getTime()}_${selectedImage.raw.name}`;
    setImageName(imageName)
  }

  const validate = () => {
    if ((title && description) && (imageUrl || image)) {
      return false
    }
    return true;
  }

  const clearAllField = () => {
    setDescription('')
    setTitle('');
    setImageName('')
    setImage()
    setImageUrl("")
    setShowToast(true)
  }


  const isSuperAdmin = () => {
    return (user?.claims?.role === 'admin') || (user?.claims?.role === 'superAdmin')
  }




  return (
    <>
      <div class="product-card ">
        <div class="card-body">
          <div className='title-container'>
            <h5 class="card-title ">{'Notification'}  </h5></div>
            <div className='flex-row'>
              {/* <i class="bi bi-card-image" style={{fontSize:'130px',marginRight:'20px'}} alt='add image'></i> */}
              <Form style={{ marginLeft: '10%' }}>

                <Form.Group className="mb-3" controlId="catalogue.ControlInput1">

                  <ImageUpload onFileSelect={onFileSelect} url={''} />

                </Form.Group>

                <h4>OR</h4>
                <Form.Group className="mb-3" controlId="catalogue.ControlInput1">
                  <Form.Label style={{ marginTop: 15 }}>Enter Image Url</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Image Url"
                    onChange={handleImageUrlChange}
                    value={imageUrl}

                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="catalogue.ControlInput1">
                  <Form.Label style={{ marginTop: 15 }}>Enter Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Title"
                    onChange={handleTitleChange}
                    value={title}

                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="catalogue.ControlTextarea1"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea"
                    rows={4}
                    placeholder="Enter description"
                    value={description}
                    onChange={handleDescChange} />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="catalogue.ControlTextarea1"
                >
                  <Button className={'primary-button'} onClick={sendNotificationProcess} disabled={validate()}>
                    {"Send Notification"}
                  </Button>
                </Form.Group>

              </Form>


            </div>
            {loading ? <Loader loading={loading} /> : null}
            {showToast ? <ToastMessage url={(image?.preview) || imageUrl} title={'Notification Sent..'} /> : null}
          </div>
        </div>

    </>
  );
}

export default NotificationManager;