import React from 'react';
import './style.css';
import { Chart } from "react-google-charts";


export const data = [
    ["Year", "Sales", "Expenses"],
    ["2004", 1000, 400],
    ["2005", 1170, 460],
    ["2006", 660, 1120],
    ["2007", 1030, 540],
  ];
  
  export const options = {
    title: "Company Performance",
    curveType: "function",
    legend: { position: "bottom" },
  };

 const ReportCard=()=>{
    return <div className='col-xxl-4 col-md-6'>
        <div class="graph-card ">
        <div class="card-body">
        <h5 class="card-title">{'Reports'} <span>| {'Today'}</span></h5>
        <Chart
      chartType="LineChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
    </div>
      
    </div>
    </div>
}
export default ReportCard