import { GET_CUSTOMER_COUNT} from "../types";
import {getAPI} from "../../services/apiService"
import {GET_CUSTOMER_COUNT_URL} from "../../services/apiConstant"


export const getCustomerList = () => async (dispatch, getState) => {
  try {
    const customerListResponse= await getAPI(GET_CUSTOMER_COUNT_URL);
    console.log('customerListResponse-',customerListResponse);
    dispatch({
      type: GET_CUSTOMER_COUNT,
      payload: customerListResponse,
    });
  } catch (error) {
    console.log("Error", error);
  }
};






