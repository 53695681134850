import React from 'react';
import {useNavigate} from "react-router-dom"
import './style.css';

 const Header=()=>{
    return <>
        <div class="pagetitle">
      <h1>Dashboard</h1>
      <nav>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/home">Home</a></li>
          <li class="breadcrumb-item active">Dashboard</li>
        </ol>
      </nav>
    </div>
    </>
}
export default Header