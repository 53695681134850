import {CLEAR_STATE, LOG_OUT, VERIFY_USER } from "../types";
import {postAPI} from "../../services/apiService"
import {VERIFY_USER_URL} from "../../services/apiConstant"


export const verifyUserDetails = (userDetails) => async (dispatch, getState) => {
  try {
    //console.log('userDetails',userDetails);
    const verifyUserApi= await postAPI(VERIFY_USER_URL,userDetails);
   //console.log('verifyUserApi',verifyUserApi);
    dispatch({
      type: VERIFY_USER,
      payload: verifyUserApi,
    });
  
  } catch (error) {
    console.log("Error", error);
  }
};

export const logout = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOG_OUT,
      payload: undefined,
    });
  } catch (error) {
    console.log("Error", error);
  }
};


