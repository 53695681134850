import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import MultipleImageUpload from '../../../component/multipleImageUpload';
import { useDispatch, useSelector } from "react-redux";
import { addProduct, updateProduct } from '../../../redux/actions/productAction';
import { Loader } from '../../../component/loader';
import ToastMessage from '../../../component/toast'
import {uploadFile} from "../../../services/apiService"
import { UPLOAD_IMAGE_URL} from "../../../services/apiConstant"

var uploadedCount=0

function AddProductPopup({ showCatalogue, toggleAddProductPopup, categoryDetails, selectedItem }) {
  console.log('selectedItem', selectedItem)

  const [image, setImage] = useState({ preview: "", raw: "" });
  const [imageArray, setImageArray] = useState([]);
  const [imageArrayUrl, setimageArrayUrl] = useState([]);
  const [imageName, setImageName] = useState('');

  const [productName, setProductName] = useState();
  const [oldPrice, setOldPrice] = useState('');
  const [currentPrice, setCurrentPrice] = useState('');
  const [memberPrice, setMemberPrice] = useState('');

  const [quantity, setQuantity] = useState('');
  const [loyalityPoint, setLoyalityPoint] = useState('');

  const [productDescName, setProductDesName] = useState('');
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showFeature, setShowFeature] = useState(false);
  const [fieldList, setFieldList] = useState([]);
  const [featureData, setFeatureData] = useState([]);
  const[finalImageList,setFinalImageList]= useState([]);
  const[defaultImageIndex,setDefaultImageIndex]= useState(0);



  const dispatch = useDispatch();
  const isProduct = categoryDetails?.productType === 'product'

  useEffect(() => {
    //make sure redux is clear on firt time page load
    if (selectedItem) {
    prefillFieldValues()
    }

    // setTimeout(() => {
    //   if (selectedItem) {
    //     prefillFieldValues()
    //   }
    // }, 1000);
  }, [])

  const prefillFieldValues = () => {
    setProductName(selectedItem.itemName);
    setCurrentPrice(selectedItem.currentPrice);
    setLoyalityPoint(selectedItem.loyalityPoint);
    setQuantity(selectedItem.quantity);
    setOldPrice(selectedItem.oldPrice);
    setMemberPrice(selectedItem.memberPrice);
    setProductDesName(selectedItem.desc);
    setImageName(selectedItem.imageName);
    setimageArrayUrl(selectedItem.imageArrayUrl || [])
    setFinalImageList(selectedItem.imageArrayUrl)
    setDefaultImageIndex(selectedItem.defaultImageIndex)

    selectedItem?.features?.map((item) => {
      const data = {
        'labelName': item.labelName,
        'value': item.value,
        'isDropDown':item.isDropDown
      }

      handleFileldSave(item.labelName)
      featureData.push(data)
    })
    setFeatureData(featureData)

  }



  const saveData = (imageUrl,imageArrayUrl) => {
    let imageArray=imageArrayUrl || finalImageList
    if(selectedItem && imageArrayUrl){
      imageArray=imageArrayUrl?.concat(finalImageList)
    }
    console.log('after imageArray',imageArray)
    let data = {
      "imageName": imageName,
      "imageUrl": imageUrl || selectedItem?.imageUrl,
      "imageArrayUrl":imageArray,
      "defaultImageIndex":defaultImageIndex,
      "itemCode": "product",
      "desc": productDescName,
      "itemType": categoryDetails.itemName,
      "categoryId": categoryDetails.id,
      "itemName": productName,
      "oldPrice": oldPrice,
      "currentPrice": currentPrice,
      "memberPrice": memberPrice,
      "quantity": quantity,
      "count": 1,
      "loyalityPointValue": Math.round(Number(currentPrice) * (Number(loyalityPoint) / 100)),
      "loyalityPoint": loyalityPoint,
      "features": featureData,
      "productType": categoryDetails?.productType,
      "categoryType": categoryDetails?.categoryType,
      "lastUpdatedTime":Date.now()

    }
    console.log('data---', data);
    if (selectedItem) {
      data.id = selectedItem.id
      console.log('saveData rew', JSON.stringify(data))
      dispatch(updateProduct(data));
      setLoading(false)
      setShowToast(true)
      setTimeout(hideAllView, 2000);
    }
    else {
     dispatch(addProduct(data));

    }
  }



  const hideAllView = () => {
    setLoading(false)
    toggleAddProductPopup();
    setShowToast(false)
  }


  const onFileSelect = (selectedImage) => {
    const sanitizImageData=selectedImage?.filter((img)=>img.raw)
    if(selectedItem){ //in case of Edit --need to handle image replacement
      if(selectedItem?.imageArrayUrl?.length>selectedImage.length){
        const finalIamgeList=selectedImage?.filter((img)=>!img.raw && selectedItem?.imageArrayUrl.find((imgf)=>img?.preview===imgf?.preview))
        setFinalImageList(finalIamgeList)
      }
      else{
        setFinalImageList(selectedItem?.imageArrayUrl)
      }
    }
    selectedImage.map((si,index)=>{
      if(si.selected){
     setDefaultImageIndex(index)
      }
    })
  
    setImageArray(sanitizImageData)
    setImage(selectedImage[defaultImageIndex]);

    const imageName = `${new Date().getTime()}_${selectedImage[0].raw.name}`;
    setImageName(imageName)
  }

  const onFileDelete=(imageList)=>{
    setFinalImageList(imageList)
  }
  
  const handleAddProduct = async () => {
    setLoading(true)
    const urlArr = []
    if (imageArray.length > 0) {
      imageArray.map(async (image, index) => {
        const formData = new FormData();
        formData.append("file", image.raw);
        formData.append("fileName", image.fileName);
        formData.append('bucketName', 'dhansheera')
        formData.append('folderName', 'Products/' + categoryDetails.itemName)
        const uploadFileResponse = await uploadFile(UPLOAD_IMAGE_URL, formData);
        if (uploadFileResponse && Object.keys(uploadFileResponse).length > 0) {
          const imageUrl = uploadFileResponse.imagePublicUrl;
          urlArr.push(imageUrl)
          handleSaveDataAfterImageUpload(urlArr);
        }
      });
      
    }
    else {
      saveData()
    }
  }

  function handleSaveDataAfterImageUpload(urlArr){
    // console.log('urlArr.length',urlArr.length)
    // console.log('imageArray.length',imageArray.length)
  if (urlArr.length === imageArray.length) {
    setimageArrayUrl(urlArr);
    saveData(urlArr[0], urlArr)
    setLoading(false)
    setShowToast(true)
    setTimeout(hideAllView, 1500);
  }
  }


  const AddFeatureInput = ({ handleFileldSave }) => {
    const [fieldName, setfieldName] = useState('');

    const handleFileldChange = (e) => {
      setfieldName(e.target.value)
    }

    return <Modal show={showFeature} onHide={() => setShowFeature(false)} size='lg' style={{
      marginLeft: '33%', marginTop: '10%', width: 500
    }}>
      <Modal.Header closeButton>
        <Modal.Title>Add feature name</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <Form.Group className="mb-3" controlId="product.ControlInput-dynamic">
          <Form.Control
            type="text"
            placeholder="Enter fieldName"
            onChange={handleFileldChange}
            autoFocus
          />
        </Form.Group>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowFeature(false)}>
          Close
        </Button>
        <Button className='primary-button' onClick={() => handleFileldSave(fieldName)}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  }

  const handleProductNameChange = (e) => {
    setProductName(e.target.value);
  }
  const handleQuantityNameChange = (e) => {
    setQuantity(e.target.value)
  }

  const handleOldPriceChange = (e) => {
    setOldPrice(e.target.value);
  }

  const handleCurrentPriceChange = (e) => {
    setCurrentPrice(e.target.value);
  }
  const handleMemberPriceChange = (e) => {
    setMemberPrice(e.target.value);
  }


  const handleDescChange = (e) => {
    setProductDesName(e.target.value)
  }
  const handleLoyalityPoint = (e) => {
    setLoyalityPoint(e.target.value)
  }
  const validate = () => {
    if (productName && loyalityPoint && currentPrice
      && oldPrice && productDescName && imageName) {
      return false
    }
    return true;
  }

  const validateForBundle = () => {
    if (productName && productDescName && imageName) {
      return false
    }
    return true;
  }

  const handleFileldSave = (labelname) => {
    const arr = fieldList;
    arr.push(labelname)
    setFieldList(arr)
    setShowFeature(false)
  }
  const handleFeatureData = (fieldName, e) => {
    let _fieldDataArray = featureData
    let data = {
      'labelName': fieldName,
      'value': '',
      'isDropDown': false
    }
    let existReocord = _fieldDataArray.find((data) => data.labelName === fieldName)
    if (existReocord) {
      _fieldDataArray = featureData.filter((d) => d.labelName !== fieldName)
      data = featureData.find((d) => d.labelName === fieldName)
      data.value = e.target.value
    }
    _fieldDataArray.push(data)
    setFeatureData(_fieldDataArray)
  }


  const handleCheckBoxFeatureData = (fieldName, e) => {
    let _fieldDataArray = featureData
    let data = {
      'labelName': fieldName,
      'value': '',
      'isDropDown': e.target.checked
    }
    let existReocord = _fieldDataArray.find((data) => data.labelName === fieldName)
    if (existReocord) {
      _fieldDataArray = featureData.filter((d) => d.labelName !== fieldName)
      data = featureData.find((d) => d.labelName === fieldName)
      data.isDropDown =e.target.checked
    }
    _fieldDataArray.push(data)
    setFeatureData(_fieldDataArray)
  }


  const removeField = (labelName) => {

      let _fieldDataArray  = featureData.filter((d) => d.labelName !== labelName)
      setFeatureData(_fieldDataArray)

    const updateList = fieldList.filter((label) => label !== labelName)
    setFieldList(updateList)
    

  }

  const geFeatureValue = (item) => {
    const feature = featureData.find((d) => d.labelName === item)
    return feature?.value
  }

  const geFeatureCheckBoxValue = (item) => {
    const feature = featureData.find((d) => d.labelName === item)
    return feature?.isDropDown
  }


  return (
    <>

      <Modal show={showCatalogue} onHide={toggleAddProductPopup} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title> {selectedItem?"Update Product":"Add New Product"}
      </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className='flex-row'>
            {/* <i class="bi bi-card-image" style={{fontSize:'130px',marginRight:'20px'}} alt='add image'></i> */}
            <MultipleImageUpload urlArray={selectedItem?.imageArrayUrl} onFileSelect={onFileSelect} onFileDelete={onFileDelete} defaultImageIndex={selectedItem?.defaultImageIndex}/>
            <Form style={{ marginLeft: '10%' }}>
              <Form.Group className="mb-3" controlId="product.ControlInput1">
                <Form.Label>Product Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter product name"
                  onChange={handleProductNameChange}
                  defaultValue={productName}
                  autoFocus
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="product.ControlInput1">
                <Form.Label>Quantity (eg: 1 pcs, 1 Kg)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter quantity"
                  onChange={handleQuantityNameChange}
                  defaultValue={quantity}
                  autoFocus
                />
              </Form.Group>
              {isProduct && <>
                <Form.Group className="mb-3" controlId="product.ControlInput1">
                  <Form.Label>Old Price</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter old price"
                    onChange={handleOldPriceChange}
                    defaultValue={oldPrice}
                    autoFocus
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="product.ControlInput1">
                  <Form.Label>Current Price</Form.Label>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Control
                      type="text"
                      placeholder="Enter current price"
                      onChange={handleCurrentPriceChange}
                      defaultValue={currentPrice}
                      autoFocus
                    />
                    {/* <span style={{ textDecoration:'underline',alignSelf: 'end',padding:10,cursor:'pointer' }} onClick={()=>setShowFeature(true)}>Add More</span> */}
                  </div>

                </Form.Group>

                <Form.Group className="mb-3" controlId="product.ControlInput1">
                  <Form.Label>Member Price</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Member price"
                      onChange={handleMemberPriceChange}
                      defaultValue={memberPrice}
                      autoFocus
                    />

                </Form.Group>

              </>
              }

              {fieldList.map((item) => {
                return <Form.Group className="mb-1" controlId={`controlId${item}`}>
                  <Form.Label>{item}</Form.Label>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Control
                      type="text"
                      placeholder={`Enter ${item} details`}
                      onChange={(e) => handleFeatureData(item, e)}
                      defaultValue={geFeatureValue(item)}

                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom:10 }}>
                      <Form.Check
                        type={'checkbox'}
                        label={`Is this multiple option?`}
                        id={new Date().getMilliseconds()}
                        checked={geFeatureCheckBoxValue(item)}
                        onChange={(e) => handleCheckBoxFeatureData(item, e)}
                      />
                      <span style={{ textDecoration: 'underline', alignSelf: 'end', cursor: 'pointer' }} onClick={() => { removeField(item) }}>Remove</span>
                    </div>
                   {geFeatureCheckBoxValue(item) &&<span style={{marginBottom:10}}>Note: All value must be seprated by comma(eg: M,L,XL).</span>}
                  </div>

                </Form.Group>
              })}


              <Form.Label style={isProduct ? { marginTop: 15 } : { display: 'none' }}>Loyality point (Percentage)</Form.Label>

              <div style={isProduct ? { display: 'flex', flexDirection: 'column' } : { display: 'none' }}>
                <Form.Control
                  type="text"
                  placeholder="Enter Loyality point"
                  onChange={handleLoyalityPoint}
                  defaultValue={loyalityPoint}

                />
                <span style={{ alignSelf: 'end' }}>Point to be added: {Math.round(Number(currentPrice) * (Number(loyalityPoint) / 100))}</span>
              </div>


              <Form.Group
                className="mb-3"
                controlId="product.ControlTextarea1"
              >
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea"
                  rows={4}
                  placeholder="Enter description"
                  defaultValue={productDescName}
                  onChange={handleDescChange} />
              </Form.Group>
            </Form>

          </div>
          {loading ? <Loader loading={loading} /> : null}
          {showToast ? <ToastMessage url={image?.preview} title={productName} /> : null}
        </Modal.Body>
        <Modal.Footer>
          <div className='flex-row justify'>
            <Button className='primary-button color-green' onClick={() => setShowFeature(true)}>
              Add New Feild
            </Button>
            <div className='flex-row'>
              <Button variant="secondary" onClick={toggleAddProductPopup} style={{ marginRight: 10 }}>
                Close
              </Button>
              <Button className='primary-button' onClick={handleAddProduct} disabled={isProduct ? validate() : validateForBundle()}>
                {selectedItem?"Update Product":"Add Product"}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {showFeature && <AddFeatureInput handleFileldSave={handleFileldSave} />}
    </>
  );
}

export default AddProductPopup;