import { GET_COURIERS_LIST} from "../types";
import {postAPI,getAPI} from "../../services/apiService"
import {SHIPMENT_GET_COURIER_URL} from "../../services/apiConstant"


export const getCourierList = (body) => async (dispatch, getState) => {
  try {
    const courierResponse= await postAPI(SHIPMENT_GET_COURIER_URL,body);
    dispatch({
      type: GET_COURIERS_LIST,
      payload: courierResponse,
    });
  } catch (error) {
    console.log("Error", error);
  }
};







