import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { FileUploader } from "react-drag-drop-files";
import Form from 'react-bootstrap/Form';

export default function MultipleImageUpload({ urlArray=[], onFileSelect,onFileDelete,defaultImageIndex=0}) {
  //const [image, setImage] = useState({ preview: url || "", raw: "" });
  const [imgArr, setImgArr] = useState([])
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF","WEBP"];


  useEffect(() => {
    urlArray?.map((url,index)=>{
        const imageData={ preview: url || "", raw: "",selected: index===defaultImageIndex }
        imgArr.push(imageData)
        setImgArr(imgArr)
      })
 
      
  },[urlArray])

 

  const handleImageChange = (file) => {
    if(imgArr.length<4){
        const fileData = {
          preview: URL.createObjectURL(file),
          raw: file,
          fileName:`${new Date().getTime()}_${file.name}`
        }
        imgArr.push(fileData)
        setImgArr(imgArr)
        onFileSelect(imgArr)
        //setImage(fileData);
    }
    else{
        alert('Max image upto 4 allowed');
    }
   

  };
  const onDelete=(img)=>{
   const filterImg=imgArr.filter((im)=>im.preview!==img?.preview)
   setImgArr(filterImg)
   const updateImageArr=[]
   filterImg.map((img)=>{
    updateImageArr.push(img?.preview)
   })
   onFileDelete(updateImageArr)

  }

  const PlaceHolder=()=>{
    return <div style={{border:'1px dotted gray',textAlign:'center',height:170,cursor:'pointer'}}>
      <div style={{marginTop:'30%'}}>Drop/Upload image here</div>

    </div>
  }

  const onDefaultSelect=(img)=>{

    const modifiedSelect = imgArr.map(im => {
      if(im.preview===img?.preview){
          return { ...im, selected: true };
      }
      else{
        return { ...im, selected: false };
      }
  });
    setImgArr(modifiedSelect)
    onFileSelect(modifiedSelect)


  }




  return (
    <div>
      
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {imgArr?.map((img) => {
          return <div className="parent-imageUpload">
             {/* <CloseButton className="image-cross" /> */}
             {imgArr.length>1 &&<Form.Check // prettier-ignore
                type="radio"
                id="custom-radio"
                className="image-select-default"
                checked={img?.selected}
                onChange={()=>onDefaultSelect(img)}
              />    
              }
             {imgArr.length>1 && <i class="bi bi-trash image-cross" onClick={()=>onDelete(img)}/>}
            
            <img src={img?.preview} alt="Product Image" width="185" height="170" className="imagePreview" />
            </div>

        })
        }
      </div>
      <FileUploader
       children={<PlaceHolder/>}
        label={'Drop/Upload image here'}
        handleChange={handleImageChange}
        name="file"
        types={fileTypes}
        classes={'file-uploader'} />

    </div>
  );
}
