import {GET_OFFER_LIST, CLEAR_STATE } from "../types";

const INITIAL_STATE = null;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case GET_OFFER_LIST:
        return action.payload;
      
        case  CLEAR_STATE:
        return INITIAL_STATE;

    default:
      return INITIAL_STATE;
  }
};
