import { CLEAR_DELETE_PRODUCT, DELETE_PRODUCT } from "../types";

const INITIAL_STATE = null;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DELETE_PRODUCT:
      return {
        ...action.payload,
      };
      
        case  CLEAR_DELETE_PRODUCT:
        return INITIAL_STATE;

    default:
      return INITIAL_STATE;
  }
};
