import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {ToggleSmall} from './toggle'
import { useState } from 'react';

function ImageTiles({data,handleToggleChange,handleUpdateCatalogueShow,showProductList,onClickAddMore}) {
 const[enable,setEnable]=useState(data?.enable);
 
 const onToggleChange=()=>{
        setEnable(!enable)
        data.enable=!enable
        handleToggleChange(data)
    }

    const handleOpenPopup=()=>{
      handleUpdateCatalogueShow(data);
    }
  return (
    <Card style={{ width: '190px', height:'195px',margin:'15px',padding:'15px' }} >
       {data?.categoryType!=='addmore' && <><i class={enable?"bi bi-toggle-on toggle-left":"bi bi-toggle-off toggle-left"} onClick={onToggleChange}></i>
        <i class="bi bi-gear settings" onClick={handleOpenPopup}></i>
        </> 
      }
        {!enable &&<Card.ImgOverlay style={{}}/>}
        {/* <ToggleSmall handleToggleChange={onToggleChange}/> */}
      <Card.Img variant="top" src={data.imageUrl} className={data?.categoryType!=='addmore'?'image-card-main':'image-card-main width-unset' } style={{cursor:'pointer',width:'unset !important'}} onClick={()=>data?.categoryType ==='addmore'?onClickAddMore():showProductList(data)}/>


      <Card.Body style={{textAlign: "center",cursor:'pointer'}} 
      onClick={()=>data?.categoryType==='addmore'? onClickAddMore:showProductList(data)}>
        
        <Card.Title style={{fontSize:'14px',textAlign:'center',fontWeight:'600'}}>{data.categoryName}</Card.Title>
       
        {/* <Card.Text className='card-desc-small'  style={{fontSize:'12px',textAlign:'center',fontWeight:'600'}}>
          {data.desc?data.desc:data.itemName}
        </Card.Text> */}
      
        {/* <Button className='btn-view-small' variant="outline-secondary pointer-cursor"  onClick={()=>showProductList(data)}>{'See All Category'}</Button> */}
      </Card.Body>
    </Card>
  );
}

export default ImageTiles;