import {VERIFY_USER,LOG_OUT, CLEAR_STATE } from "../types";

const INITIAL_STATE = null;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VERIFY_USER:
      return {
        ...action.payload,
      };
      case  LOG_OUT:
        return INITIAL_STATE;
        case  CLEAR_STATE:
        return INITIAL_STATE;

    default:
      return INITIAL_STATE;
  }
};
