import axios from "axios";
import {SHIPMENT_TOKEN,SHIPMENT_CRETE_COURIER_URL} from "../services/apiConstant"
import {postAPI,getAPI} from "../services/apiService"

export const SHIP_ROKET_BASE_URL = "https://apiv2.shiprocket.in/v1/external/"
export const GET_TOKEN_URL = "auth/login"
export const CREATE_ORDER = "orders/create/adhoc"
export const FETCH_ORDER_BY_ORDERID = "orders/show/"
export const COURIER_LIST = "courier/serviceability/"



// export const post = async (url, body) => {
//     console.log('body--', body)
//     const response = await fetch(url, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(body) || '{}'
//     }).catch(err => { console.log("error in calling api", err); });
//     return await response.json()
// }

// export const postWithToken = async (url, body, token) => {
//     console.log('body--', body)
//     const response = await fetch(url, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': 'Bearer ' + token,

//         },
//         body: JSON.stringify(body) || '{}'
//     }).catch(err => { console.log("error in calling api", err); });
//     return await response.json()
// }

// export const getWithToken = async (url, token) => {
//     const header = {
//         'Content-Type': 'application/json',
//         'Authorization': 'Bearer ' + token,
//     }
//     let options = {
//         method: 'GET',
//         headers: header
    
//     }

//     const response = await fetch(url, options).catch(err => { console.log(err); });
//     return await response.json()
// }

// export const getApiWithBody = async (url, token, body) => {
//     const config = {
//         headers: {
//             'Content-Type': 'application/json',
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + token,
//         },
//         data: body,
//     }
//     console.log('courierDetails config', config)

//     const { data: res } = await axios.get(url, config)

//     return res;

// }


// export const get = async (url, body = '{}') => {
//     const response = await fetch(url, {
//         method: 'GET',
//         headers: { 'Content-Type': 'application/json' },
//     }).catch(err => { console.log(err); });
//     return await response.json()
// }





export const createOrder = async (body) => {
    const orderDetails= await postAPI(SHIPMENT_CRETE_COURIER_URL,body).catch((error) => {
        console.log('orderDetails error', error)
    
      });

      return orderDetails;

  }




// const shipRocketToken = async () => {

//     const tokenDetails = await getAPI(SHIPMENT_TOKEN).catch((error) => {
//         console.log('tokenDetails error', error)
//     });
//     if (tokenDetails) {
//         return JSON.parse(JSON.stringify(tokenDetails))?.token

//     }
//     else {
//         return null;

//     }

// }




