
import React from 'react';
import logo from '../assets/logo-home.png'


export function SearchBar ({handleTextSearch ,value,title,css=''}) {
  return (<div className={"search-form d-flex align-items-center "+css}>
    <div class="search-bar">
      <div class="search-form d-flex align-items-center" >
        <input type="text" name="query" placeholder={title || "Enter search keyword"}
           value={value}
          title={title || "Enter search keyword"} onChange={handleTextSearch}
          />
        <button title="Search"><i class="bi bi-search"></i></button>
      </div>
    </div>
  </div>);
}