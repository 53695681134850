import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Confirm({title,body,onConfirm,onCancel}) {
  return (
    <div
      className="modal show"
      style={{ display: 'block',  }}
    >
      <Modal.Dialog>
        <Modal.Header closeButton onClick={onCancel}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{body}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>Close</Button>
          <Button variant="primary" onClick={onConfirm} >Delete</Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
}

export default Confirm;