import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ImageUpload from '../../component/imageUpload';
import { useDispatch, useSelector } from "react-redux";
import { uploadImage, clear } from "../../redux/actions/uploadImageAction";
import { getCategoryList } from '../../redux/actions/categoryAction';
import { Loader } from '../../component/loader';
import ToastMessage from '../../component/toast'
import {postAPI} from "../../services/apiService"
import {CREATE_OFFER__URL} from "../../services/apiConstant"


function AddOffer({ show, togglePopup }) {
  const FOLDER_PATH = 'mobile/offers'
  const [allCategory,setAllCategory]=useState([])

  const [image, setImage] = useState({ preview: "", raw: "" });
  const [imageName, setImageName] = useState('');

  const [pageName, setPageName] = useState('');
  const [sequence, setSequence] = useState('');
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [categoryType, setCategoryType] = useState('');



  const pages=["dashboard","product","productDetails","category"]
  const dispatch = useDispatch();
  const uploadFileReponse = useSelector((state) => state.uploadFileReducer);
  const categoryList = useSelector((state) => state.getCategoryReducer) || [];

  useEffect(() => {
    dispatch(clear());
    clearAllField()
    dispatch(getCategoryList());
  }, [])
  
  useEffect(() => {
    if(categoryList?.length>0){
      console.log('categoryList--',categoryList)
      setAllCategory(categoryList)
    }
  
  }, [categoryList])

  useEffect(() => {
    // console.log('useEffect uploadFileReponse',uploadFileReponse)
    if (uploadFileReponse && Object.keys(uploadFileReponse).length > 0) {
      const imageUrl = uploadFileReponse.imagePublicUrl;
      createOffer(imageUrl);
    }
  }, [uploadFileReponse])


  const clearAllField=()=>{
    setPageName("");
    setSequence("");
    setImage({ preview: "", raw: "" })
    setImageName('')
  }

 

  


  const getCategoryTypeValue=(categoryId)=>{
    const filterCategory=allCategory.find((ac)=>ac.id===categoryId)
    return filterCategory.categoryName
  
    }
    const getCategoryItem=(categoryId)=>{
      const filterCategory=allCategory.find((ac)=>ac.id===categoryId)
      return filterCategory
    
      }
  

const createOffer=async(imageUrl)=>{
  if (imageUrl && pageName) {
    const data = {
      "imageName": imageName,
      "imageUrl": imageUrl,
      "pageName":pageName,
      "sequence":sequence,
      "dateTime":Date.now(),
      "categoryType": getCategoryTypeValue(categoryType),
      "categoryItem":getCategoryItem(categoryType)


    }

  const addOffer=postAPI(CREATE_OFFER__URL,data)
    setLoading(false)
    setShowToast(true)
    clearAllField();
    togglePopup();
  }
}

  const onFileSelect = (selectedImage) => {
    setImage(selectedImage);
    const imageName = `${new Date().getTime()}_${selectedImage.raw.name}`;
    setImageName(imageName)
  }

  const handleAddOffer = () => {
    setLoading(true)
    const formData = new FormData();
    formData.append("file", image.raw);
    formData.append("fileName", imageName);
    formData.append('bucketName', 'dhansheera')
    formData.append('folderName', FOLDER_PATH)
    dispatch(uploadImage(formData))
  }

  const handlePageChange = (e) => {
    setPageName(e.target.value)
  }

  const handleSequenceChange = (e) => {
    setSequence(e.target.value);
  }
  const handleCategoryChange = (e) => {
    console.log(e.target.value)
    setCategoryType(e.target.value)
  }


  const validate = () => {
    if ( imageName && pageName && sequence) {
      return false
    }
    return true;
  }

 
  return (
    <>

      <Modal show={show} onHide={togglePopup} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Add New Offer</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className='flex-row'>
            <ImageUpload onFileSelect={onFileSelect} />
            <Form style={{ marginLeft: '10%' }}>
              <Form.Group className="mb-3" controlId="catalogue.ControlInput1">
             
              <Form.Label>Category Type</Form.Label>
                <Form.Select aria-label="Default select example" size="lg" onChange={handleCategoryChange}>
                  <option>Please select category</option>
                  {allCategory?.map((cat)=>{
                    return  <option value={cat?.id}>{cat?.categoryName}</option>
                  })}
               
                </Form.Select>

                <Form.Label>Select Page</Form.Label>
                <Form.Select aria-label="Default select example" size="lg" onChange={handlePageChange}>
                  <option>Please select Page</option>
                  {pages?.map((page)=>{
                    return  <option value={page}>{page}</option>
                  })}
               
                </Form.Select>

                <Form.Label style={{ marginTop: 15 }}>Sequence Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter sequence Number"
                  onChange={handleSequenceChange}
                />
              
              </Form.Group>
     
            </Form>

          </div>
          {loading ? <Loader loading={loading} /> : null}
          {showToast ? <ToastMessage url={image.preview} title={pageName} /> : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={togglePopup}>
            Close
          </Button>
          <Button className={'primary-button'} onClick={handleAddOffer} disabled={validate()}>
            Add Offer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddOffer;