import { GET_ADMIN_USERS} from "../types";
import {getAPI} from "../../services/apiService"
import {GET_ADMIN_USER_URL} from "../../services/apiConstant"


export const getAdminUserList = () => async (dispatch, getState) => {
  try {
    const aminUserListResponse= await getAPI(GET_ADMIN_USER_URL);
    console.log('getAdminUserListResponse-',aminUserListResponse);
    dispatch({
      type: GET_ADMIN_USERS,
      payload: aminUserListResponse,
    });
  } catch (error) {
    console.log("Error", error);
  }
};







