import {BrowserRouter,Routes,Route} from "react-router-dom";
import Login from "../pages/login/login";
import Home from "../pages/home/home";


 function Router() {
  return (<>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login />}/>
      <Route exact path="/home" element={<Home/>}/>
        <Route path="*" element={<h1 >Page no found</h1>} />
    </Routes>
  </BrowserRouter>
  </>
    
  );
}

export default Router;


