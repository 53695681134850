import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from "react-redux";
//import { addProduct, updateProduct } from '../../../redux/actions/productAction';
import { Loader } from '../../component/loader';
import ToastMessage from '../../component/toast'

import {ADD_MEMBER_URL} from "../../services/apiConstant"
import {postAPI} from "../../services/apiService"

function AddMemberPopup({ show,togglePopup}) {

  const [userFullName, setUserFullName] = useState();
  const [memberId, setMember] = useState('');
  const [memberName, setMemberName] = useState('');
  const [sideName, setSideName] = useState('');
  const [referalMemberId, setReferalMemberId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isNewMember, setIsNewMember] = useState('');


  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const dispatch = useDispatch();

 const roles=['contentAdmin','admin','superAdmin','readOnly']

  const saveData = async() => {
    setLoading(true)
    let data = {
        "memberId":memberId,
        "memberName":memberName,
        "sideName":sideName,
        "referalMemberId":referalMemberId,
        "mobileNumber": Number(phoneNumber),
        "isMember":true,
        "isNewMember":isNewMember
    }
    console.log('data---', data);
    const addNewMemberResponse= await postAPI(ADD_MEMBER_URL,data);
    if(addNewMemberResponse){
      setLoading(false)
      togglePopup(false)
    }
  }

  const validate = () => {
    if (memberName && memberId && phoneNumber && isNewMember) {
      return false
    }
    return true;
  }

  return (
    <>
      <Modal show={show} onHide={togglePopup} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title> {"Add New Member"}
      </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className='flex-row'>
            <Form style={{ marginLeft: '20%' }}>

              <Form.Group className="mb-3" controlId="product.ControlInput1">
                <Form.Label>Member ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter memberId"
                  onChange={(e)=>setMember(e.target.value)}
                  autoFocus
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="product.ControlInput1">
                <Form.Label>Member Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter member name"
                  onChange={(e)=>setMemberName(e.target.value)}
                  autoFocus
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="product.ControlInput1">
                <Form.Label>Side(Left, Right)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Side"
                  onChange={(e)=>setSideName(e.target.value)}
                  autoFocus
                />
              </Form.Group>


              <Form.Group className="mb-3" controlId="product.ControlInput1">
                <Form.Label>Referal Member ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter referal memberId"
                  onChange={(e)=>setReferalMemberId(e.target.value)}
                  autoFocus
                />
              </Form.Group>

             
              <Form.Group className="mb-3" controlId="product.ControlInput1">
                <Form.Label>Registerd Mobile Number (same as app)</Form.Label>
                <Form.Control
                  type="text"
                  maxLength={10}
                  placeholder="Enter Mobile"
                  onChange={(e)=>setPhoneNumber(e.target.value)}
                  autoFocus
                />
              </Form.Group>
              
              <Form.Group className="mb-3" controlId="product.ControlInput1">

              <Form.Label>Fresh Member (Free goodies applicable worth Rs.999)</Form.Label>
                <Form.Select aria-label="Default select example" size="lg"  onChange={(e)=>setIsNewMember(e.target.value)}>
                  <option>Please select</option>
                  {["Yes","No"]?.map((role)=>{
                    return  <option value={role}>{role}</option>
                  })}
               
                </Form.Select>
                </Form.Group>


            </Form>

          </div>
          {loading ? <Loader loading={loading} /> : null}
        </Modal.Body>
        <Modal.Footer>
          <div className='flex-row justify'>
            <div className='flex-row'>
              <Button variant="secondary" onClick={togglePopup} style={{ marginRight: 10 }}>
                Close
              </Button>
              <Button className='primary-button' onClick={saveData} disabled={ validate()}>
                {"Register"}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddMemberPopup;