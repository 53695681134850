import {GET_CATEGORY, CLEAR_STATE } from "../types";

const INITIAL_STATE = null;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case GET_CATEGORY:
        return action.payload;
      
        case  CLEAR_STATE:
        return {
         undefined,
          action,
        };

    default:
      return INITIAL_STATE;
  }
};
