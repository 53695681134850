import React from 'react';
import {useNavigate} from "react-router-dom"
import './style.css';
  
const SideBar = ({onSelect,selectedPage,user}) => {
  const navigate = useNavigate();




  const isSuperAdmin=()=>{
      return (user?.claims?.role==='superAdmin')
    }

    const isAdmin=()=>{
      return (user?.claims?.role==='superAdmin' || user?.claims?.role==='admin')
    }
    
  return (
      <>
      <aside id="sidebar" class="sidebar">

<ul class="sidebar-nav" id="sidebar-nav">

  <li class="nav-item">
    <a class={`nav-link ${selectedPage==='dashboard'?'':'collapsed'}`} href="/home">
      <i class="bi bi-grid"></i>
      <span>Dashboard</span>
    </a>
  </li>


  <li class="nav-item">
    <a class={`nav-link ${selectedPage==='products'?'':'collapsed'}`}  onClick={()=>onSelect('products')}>
      <i class="bi bi-menu-button-wide"></i>
      <span>Product Listing</span>
    </a>
  </li>

  <li class="nav-item">
    <a class={`nav-link ${selectedPage==='orders'?'':'collapsed'}`} data-bs-target="#forms-nav" data-bs-toggle="collapse" href="#" onClick={()=>onSelect('orders')}>
      <i class="bi bi-journal-text"></i><span>Order Details</span>
    </a>

  </li>

  <li class="nav-item">
    <a class="nav-link collapsed" data-bs-target="#forms-nav" data-bs-toggle="collapse" href="#" onClick={()=>onSelect('offers')}>
      <i class="bi bi-journal-text"></i><span>Offers Management</span>
    </a>

  </li>

 

  <li class="nav-item">
    <a class="nav-link collapsed" data-bs-target="#charts-nav" data-bs-toggle="collapse" href="#">
     <a href='https://dashboard.razorpay.com/app/dashboard' target='blank' style={{textDecoration:"none", color: '#444444'}}><i class="bi bi-bar-chart"></i><span>Billing</span></a> 
    </a>

  </li>

  {/* <li class="nav-item">
    <a class="nav-link collapsed" data-bs-target="#icons-nav" data-bs-toggle="collapse" href="#">
      <i class="bi bi-gem"></i><span>Icons</span><i class="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="icons-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
      <li>
        <a href="icons-bootstrap.html">
          <i class="bi bi-circle"></i><span>Bootstrap Icons</span>
        </a>
      </li>
      <li>
        <a href="icons-remix.html">
          <i class="bi bi-circle"></i><span>Remix Icons</span>
        </a>
      </li>
      <li>
        <a href="icons-boxicons.html">
          <i class="bi bi-circle"></i><span>Boxicons</span>
        </a>
      </li>
    </ul>
  </li> */}

  <li class="nav-heading">SETTINGS</li>

  {/* <li class="nav-item">
    <a class="nav-link collapsed" href="users-profile.html">
      <i class="bi bi-person"></i>
      <span>Profile</span>
    </a>
  </li> */}

  {/* <li class="nav-item">
    <a class="nav-link collapsed" href="pages-faq.html">
      <i class="bi bi-question-circle"></i>
      <span>Help & Support</span>
    </a>
  </li>

  <li class="nav-item">
    <a class="nav-link collapsed" href="pages-contact.html">
      <i class="bi bi-envelope"></i>
      <span>Contact</span>
    </a>
  </li> */}
{isSuperAdmin() &&
  <li class="nav-item">
    {/* <a class="nav-link collapsed" href="pages-register.html"> */}
    <a class={`nav-link ${selectedPage==='register'?'':'collapsed'}`}  onClick={()=>onSelect('register')}>
      <i class="bi bi-person"></i>
      <span>User Management</span>
    </a>
  </li>
}
  <li class="nav-item">
    {/* <a class="nav-link collapsed" href="pages-register.html"> */}
    <a class={`nav-link ${selectedPage==='notifications'?'':'collapsed'}`}  onClick={()=>onSelect('notifications')}>

      <i class="bi bi-bell-fill"></i>
      <span>Notification Manager</span>
    </a>
  </li>

  {isSuperAdmin() &&
  <li class="nav-item">
    {/* <a class="nav-link collapsed" href="pages-register.html"> */}
    <a class={`nav-link ${selectedPage==='member'?'':'collapsed'}`}  onClick={()=>onSelect('member')}>
      <i class="bi bi-person"></i>
      <span>Members</span>
    </a>
  </li>
}
 
{isAdmin() &&
  <li class="nav-item">
    {/* <a class="nav-link collapsed" href="pages-register.html"> */}
    <a class={`nav-link ${selectedPage==='refund'?'':'collapsed'}`}  onClick={()=>onSelect('refund')}>
      <i class="bi bi-person"></i>
      <span>Refund & Settlement</span>
    </a>
  </li>
}
</ul>

</aside>
      </>
  )
};
  
export default SideBar;