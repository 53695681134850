
import React, { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';
import { getAPI } from "../../services/apiService"
import {GET_VENDORS_URL} from "../../services/apiConstant"

import { ReturnStatus } from './OrderEnum'
import Form from 'react-bootstrap/Form';

function ProcessOrderPopup(props) {
  const [selectionType, setSelectionType] = useState(null)
  const [vendors,setVendors]=useState([])
  const [selectedVendor,setSelectedVendor]=useState(null)

  useEffect(() => {

  }, [])

  useEffect(() => {
    if(selectionType){
      getVendorList()
    }

  }, [selectionType])

  const getVendorList =async ()=>{
    const vendorListResponse= await getAPI(GET_VENDORS_URL);
    setVendors(vendorListResponse)

  }


  const handleSelect = (item) => {
    setSelectionType(item);
    if(item==='shipRocket'){
    props.onSelectCourier()
    }

  };

  const onClose=()=>{
    setSelectionType(null);
    props.onCloseOrderProcessPopup()
  }

  const handleSubmit =()=>{
    setSelectionType(null);
    const selectedVendorDetails=vendors.find(v=>v.uid===selectedVendor)
   props.submitOrderToVendor(props.selectedOrder,selectedVendorDetails)
  }

  return (<>
    <Modal show={true} onHide={onClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title> {selectionType === 'vendor'?"Complete Order":"How to process order"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <div className='wrapper-user' key={"user"}>
         <div style={{display:'flex',flexDirection:'row'}}>
          <Form.Check
            inline
            value='shipRocket'
            type={'radio'}
            id={`inline-${'radio'}-1`}
            onChange={() => handleSelect('shipRocket')}
            checked={selectionType === 'shipRocket'}
            label='ShipRocket'
          />
           <Form.Check
            inline
            label='Vendor'
            value='vendor'
            type={'radio'}
            id={`inline-${'radio'}-2`}
            onChange={() => handleSelect('vendor')}
            checked={selectionType === 'vendor'}
          />

           
    
    </div>
          {selectionType === 'vendor' &&
            <div style={{margin:20}}>
              <Form.Select aria-label="Default select example" size="lg" onChange={(e) => setSelectedVendor(e.target.value)}>
                <option>Please select vendor</option>
                {vendors?.map((vendor) => {
                  return <option value={vendor.uid}>{vendor.displayName}</option>
                })}

              </Form.Select>
            </div>
          }
          {selectionType === 'vendor' &&   
          props.data.orders?.map((item,index)=>{
          return <div className='wrapper-user' key={"products"+index}>
          <div className='row-home mt-20'>
            <img src={item?.productDetails?.imageUrl} alt="item" width="100" height="120" />
            <div className='column-home ml-20'>
              <div className='prd-name'>{item?.productDetails?.itemName}</div>
              <div><span>Quantity:</span> {item?.quantity}</div>
              <div><span>Category:</span> {item?.productDetails?.itemType}</div>
              {item?.productDetails?.size && <div><span>Size:</span> {item?.productDetails?.size}</div>}
              <div><span>Price Per Unit:</span> {item.productDetails?.currentPrice}</div>
              <div><span>Delivery Charges:</span> {props.data?.paymentInfo?.deliveryCharges || 0}</div>

            </div>
          </div>
        </div>  
        })
        }
       
        </div>
      </Modal.Body>
      <Modal.Footer>
      {selectionType === 'vendor' &&
          <>
        <div style={{alignSelf:'left',fontWeight:'600',marginRight:50}}><span>Total Amount:</span> Rs. {props.data?.paymentInfo?.totalPayment}</div>

            <Button variant="secondary" onClick={onClose}>Close</Button>
            <Button variant="primary" onClick={() => handleSubmit()} disabled={selectedVendor===null}> Send Order to Vendor</Button>
          </>
}
      </Modal.Footer>
    </Modal>
  </>)



}

export default ProcessOrderPopup