import FadeLoader from "react-spinners/FadeLoader";
import './index.css';

export const Loader=({loading})=>{
    return  <div className='loader-center'>
    <FadeLoader
      color={'#36d7b7'}
      loading={loading}
      size={150}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
    <span>Loading...</span>
    
    </div>
  }
  