import React, {useEffect,useState} from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyUserDetails,logout } from "../../redux/actions/loginAction";
import './login.css';
import logo from '../../assets/dhansheera.png'
import { Loader } from '../../component/loader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
const firebaseConfig = {
  apiKey: "AIzaSyD8ATBJsfYzBysBk5gNGbumyU5MsZl1NYk",
  authDomain: "dhansheera-97519.firebaseapp.com",
  projectId: "dhansheera-97519",
  storageBucket: "dhansheera-97519.appspot.com",
  messagingSenderId: "711569804117",
  appId: "1:711569804117:web:83ca56ed28ea51c2f17ddc",
  measurementId: "G-MZ2VMWCXH6"
};
firebase.initializeApp(firebaseConfig);

function Login() {
    const navigate = useNavigate();
    const [user,setUser]=useState('');
    const [password,setPassword]=useState('');
    const [error,setError]=useState('');
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    
    const currentUser = useSelector((state) => state.userReducer);

    
  
    useEffect(() => {
      //make sure redux is clear on firt time page load
    dispatch(logout);
  }, [])

   useEffect(() => {
    console.log('currentUser',currentUser);
   if(currentUser &&  Object.keys(currentUser).length>0  && !currentUser.error){
    navigate("/home");
   }
   else if(currentUser && currentUser.error){
    setError(currentUser.error)
   }
   setLoading(false)

  }, [currentUser])
    
const handleLogin=()=>{
if(user && password){
  signInWithEmailPassword()

  // const userData={email:user,password:password}
  // setLoading(true)
  //  dispatch(verifyUserDetails(userData));
}
else{
    alert('please enter valid username or password');
}
}

const handleUserNameChange=(e)=>{
    setUser(e.target.value)
}

const handlePasswordChange=(e)=>{
    setPassword(e.target.value)

}

function signInWithEmailPassword() {
  var email = user;
  // [START auth_signin_password]
  firebase.auth().signInWithEmailAndPassword(email, password)
    .then(async (userCredential) => {
      // Signed in
      var user = userCredential.user;
      const _user = await user.getIdTokenResult()

      if(_user?.claims?.role!=='vendor'){
        navigate("/home");
      }
      else{
        setError('You are not authorized to access this')

      }
    })
    .catch((error) => {
      console.log('userCredential error',error)
      var errorCode = error.code;
      var errorMessage = error.message;
      setError(errorMessage)

    });
  // [END auth_signin_password]
}


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      console.log('do validate')
      handleLogin() 
    }
  
}




  return (
   <>
   <div className='main'>
   
  <div className="imgcontainer">
    <img src={logo} alt="Avatar" className="avatar"/>
  </div>

  <div className="container">
    <label for="uname"><b>Email</b></label>
    <input type="text" placeholder="Enter Email" name="uname" onChange={handleUserNameChange} onKeyDown={handleKeyDown} required/>

    <label for="psw"><b>Password</b></label>
    <input type="password" placeholder="Enter Password" name="psw" onChange={handlePasswordChange} onKeyDown={handleKeyDown} required/>
      <p className='error_msg'>{error}</p>
     {loading?<Loader loading={loading}/>:null}
    <button onClick={handleLogin} onSubmit={handleLogin} className='buttonLogin'>Login</button>

  </div>

  <div className="container" >
    <span >Powerd by <a href='http://effableSoft.com' target="_blank"> effableSoft</a></span>
  </div>
</div>
   </>
  );
}

export default Login;
