import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';


import '../product.css';

const ProductListItem=({item,index,handleDelete,handleToggleChange,handleEdit,user})=>{
    const[enable,setEnable]=useState(item.enable);
   
   
    const onToggleChange=()=>{
        setEnable(!enable)
        item.enable=!enable
        handleToggleChange(item)
    }

    const isSuperAdmin=()=>{
        return (user?.claims?.role==='admin') || (user?.claims?.role==='superAdmin')
      }


    return  <ListGroup.Item
    key={index+"li"}
      as="li"
      className="d-flex justify-content-between align-items-start"
    >

{!enable &&<Card.ImgOverlay style={{}}/>}
      <img className='product-item-image' src={item?.imageArrayUrl?.[(item?.defaultImageIndex) || 0] || item.imageUrl} />
      <div className="me-auto product-list-row ml-20">
      <div className='flex-row'>
          <div className='bold-font'>Product Id : </div>
          <div className=" ml-10 txt-eclipse"> {item.id}</div>
        </div>

        <div className='flex-row'>
          <div className='bold-font'>Product Name : </div>
          <div className=" ml-10 txt-eclipse"> {item.itemName}</div>
        </div>
        <div className='flex-row'>
          <div className='bold-font'>Current Price :</div>
          <div className='ml-10'>₹{item.currentPrice|| '---'}</div>
        </div>

        <div className='flex-row'>
          <div className='bold-font'>Member Price :</div>
          <div className='ml-10'>₹{item.memberPrice|| '---'}</div>
        </div>

        <div className='flex-row'>
          <div className='bold-font'>Old Price :</div>
          <div className='ml-10'>₹{item.oldPrice || '---'}</div>
        </div>

        <div className='flex-row'>
          <div className='bold-font'>Quantity :</div>
          <div className='ml-10'>{item.quantity || '---'}</div>
        </div>

        <div className='flex-row'>
          <div className='bold-font'>Description:</div>
          <div className='ml-10 txt-eclipse'>{item.desc || '--'}</div>
        </div>

      </div>

      {/* <Badge bg="primary" pill>
        14
      </Badge> */}

      <div>
        <div className=' settings'>
       {isSuperAdmin() && <i class="bi bi-trash product-setting" onClick={()=>handleDelete(item)}></i>}
        <i class="bi bi-pen product-setting ml-10" onClick={()=>handleEdit(item)}></i>

        </div>
      <i class={enable?"bi bi-toggle-on product-toggle-left":"bi bi-toggle-off product-toggle-left"} onClick={onToggleChange}></i>
        
      </div>
    </ListGroup.Item>
}

export default ProductListItem