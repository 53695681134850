const LOCAL_URL='http://localhost:8080'
const UAT_URL='https://adminapi-vfbmltmf3a-de.a.run.app'

export const BASE_URL= UAT_URL

export const VERIFY_USER_URL= BASE_URL+'/verifyUser'

export const ADD_CATALOGUE_URL= BASE_URL+'/addCatalogue'
export const UPDATE_CATALOGUE_URL= BASE_URL+'/updateCatalogue'
export const GET_CATALOGUE_URL= BASE_URL+'/catalogues'
export const DELETE_CATALOGUE_URL= BASE_URL+'/catalogue/'

export const ADD_CATEGORY_URL= BASE_URL+'/addcategory'
export const UPDATE_CATEGORY_URL= BASE_URL+'/updatecategory'
export const GET_CATEGORY_URL= BASE_URL+'/category'
export const DELETE_CATEGORY_URL= BASE_URL+'/category/'

export const GET_PRODUCT_URL= BASE_URL+'/products'
export const GET_PRODUCT_BY_CATEGORY_URL= BASE_URL+'/productbycategory'
export const ADD_PRODUCT_URL= BASE_URL+'/addProduct'
export const DELETE_PRODUCT_URL=BASE_URL+'/product/'
export const UPDATE_PRODUCT_URL=BASE_URL+'/updateProduct'

export const GET_ACTIVE_ORDERS_URL=BASE_URL+'/activeorderlist'
export const GET_RETURN_ORDERS_URL=BASE_URL+'/returnorderlist'


export const UPDATE_ORDERS_URL=BASE_URL+'/updateOrder/'
export const CANCEL_ORDERS_URL=BASE_URL+'/cancelOrder'
export const RETURN_ORDERS_URL=BASE_URL+'/returnOrder'



export const GET_CUSTOMER_COUNT_URL=BASE_URL+'/customer/count'
export const ADD_ADMIN_USER_URL=BASE_URL+'/createUser'
export const GET_ADMIN_USER_URL=BASE_URL+'/users'
export const DELETE_ADMIN_USER_URL=BASE_URL+'/user'

export const SHIPMENT_GET_COURIER_URL=BASE_URL+'/fetchCourierList'
export const SHIPMENT_CRETE_COURIER_URL=BASE_URL+'/createOrder'
export const SHIPMENT_TOKEN=BASE_URL+'/fetchToken'

export const GET_OFFER_LIST_URL=BASE_URL+'/offers'
export const CREATE_OFFER__URL=BASE_URL+'/createOffer'
export const DELETE_OFFER_URL=BASE_URL+'/offer'


export const ADD_MEMBER_URL=BASE_URL+'/addMember'
export const DELETE_MEMBER_URL=BASE_URL+'/member/'

export const GET_MEMBERS_URL=BASE_URL+'/members'



export const SEND_NOTIFICATION_URL=BASE_URL+'/notification/offer'

export const UPLOAD_IMAGE_URL= BASE_URL+'/uploadFile'
export const GET_WALLET_DETAILS=BASE_URL+'/walletInfo/'

export const GET_VENDORS_URL=BASE_URL+'/vendors'

