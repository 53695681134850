import { GET_CATEGORY,ADD_CATEGORY ,DELETE_CATEGORY,UPDATE_CATEGORY,CLEAR_UPLOAD_CATEGORY} from "../types";
import {postAPI,getAPI, deleteAPI,putAPI} from "../../services/apiService"
import {ADD_CATEGORY_URL,GET_CATEGORY_URL,DELETE_CATEGORY_URL,UPDATE_CATEGORY_URL} from "../../services/apiConstant"


export const addCategory = (data) => async (dispatch, getState) => {
  try {
    const addCategoryResponse= await postAPI(ADD_CATEGORY_URL,data);
    dispatch({
      type: ADD_CATEGORY,
      payload: addCategoryResponse,
    });
  
  } catch (error) {
    console.log("Error", error);
  }
};
export const updateCategory = (data) => async (dispatch, getState) => {
  try {
    const updateCategoryResponse= await putAPI(UPDATE_CATEGORY_URL,data);
    console.log('updateCategoryResponse-',updateCategoryResponse);

    dispatch({
      type: UPDATE_CATEGORY,
      payload: updateCategoryResponse,
    });
  
  } catch (error) {
    console.log("Error", error);
  }
};

export const getCategoryList = () => async (dispatch, getState) => {
  try {
    const catalogueResponse= await getAPI(GET_CATEGORY_URL);
    console.log('catalogueResponse-',catalogueResponse);
    dispatch({
      type: GET_CATEGORY,
      payload: catalogueResponse,
    });
  } catch (error) {
    console.log("getCategoryList Error", error);
  }
};

export const deleteCategory = (id,body) => async (dispatch, getState) => {
  try {
    const deleteCategoryResponse= await deleteAPI(DELETE_CATEGORY_URL+id,body);
    dispatch({
      type: DELETE_CATEGORY,
      payload: deleteCategoryResponse,
    });
  
  } catch (error) {
    console.log("Error", error);
  }
};


export const clear = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLEAR_UPLOAD_CATEGORY,
      payload: undefined,
    });
  } catch (error) {
    console.log("Error", error);
  }
};


